<template>
  <v-container fluid class="fill-height pa-0">
    <v-container
      fluid
      class="d-flex justify-center align-center flex-wrap pa-0 fill-height"
    >
      <v-row class="justify-center fill-height">
        <v-col md="8" lg="9" class="pt-0 mt-0">
          <div class="pt-0 pb-0 mb-0 mt-0">
            <v-row class="justify-center ma-0 ml-1 pa-0">
              <v-col class="pa-0 ma-0 pt-2" cols="2">
                <v-text-field
                  class="pa-0 ma-0"
                  append-icon="mdi-magnify"
                  clearable
                  dense
                  outlined
                  v-model="innerSearch"
                  label="Pretraži artikle"
                  hide-details
                  @keydown.enter="innerSearchItems()"
                  @click:clear="innerSearchItems({ reset: true })"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <div class="pt-0 pb-0 mb-0 mt-0">
            <div class="categories pt-0 mt-0">
              <v-row class="justify-center ma-0 ml-1 pa-0">
                <v-tabs
                  v-model="category"
                  hide-slider
                  height="105"
                  show-arrows
                  color="success"
                  class="flex-grow-0 flex-shrink-0 mt-0 pt-0 mb-0 pb-0"
                >
                  <v-tab
                    v-for="(category, i) in categories"
                    :key="i"
                    @click="selectCategory(category)"
                    @keyup.enter="selectCategory(category)"
                    :id="`category-${i}`"
                    :ref="`category-${i}`"
                    class="my-back"
                    style="height: 120px; padding-top: 0px; margin-top: 0px"
                  >
                    <div class="mb-0">
                      <v-avatar :size="$vuetify.breakpoint.smAndDown ? 55 : 70">
                        <v-img
                          v-if="category.picture"
                          :src="category.picture"
                          contain
                        >
                        </v-img>
                        <v-icon
                          v-else
                          :size="$vuetify.breakpoint.smAndDown ? 55 : 70"
                        >
                          mdi-image
                        </v-icon>
                      </v-avatar>
                      <p>{{ category.name }}</p>
                    </div>
                  </v-tab>
                  <v-menu
                    bottom
                    v-model="menu"
                    offset-y
                    nudge-bottom="10"
                    nudge-left="310"
                    v-if="moreCategories.length > 0"
                  >
                    <template v-slot:activator="{ on: onMenu }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-avatar
                            class="mt-1"
                            v-on="on"
                            v-bind="attrs"
                            :size="$vuetify.breakpoint.smAndDown ? 55 : 70"
                          >
                            <v-icon
                              :size="$vuetify.breakpoint.smAndDown ? 35 : 50"
                              v-on="onMenu"
                            >
                              mdi-view-dashboard-outline
                            </v-icon>
                          </v-avatar>
                        </template>
                        <span>+ {{ moreCategories.length }} kategorija</span>
                      </v-tooltip>
                    </template>
                    <v-card v>
                      <v-card-text>
                        <div class="ai-app-menu">
                          <v-row>
                            <v-col
                              cols="4"
                              v-for="(moreCategory, i) in moreCategories"
                              :key="i"
                              class="more-category"
                              @click="addToCategories(moreCategory)"
                            >
                              <v-avatar
                                :size="$vuetify.breakpoint.smAndDown ? 55 : 70"
                              >
                                <v-img
                                  v-if="moreCategory.picture"
                                  :src="moreCategory.picture"
                                  contain
                                >
                                </v-img>
                                <v-icon
                                  v-else
                                  :size="
                                    $vuetify.breakpoint.smAndDown ? 55 : 70
                                  "
                                >
                                  mdi-image
                                </v-icon>
                              </v-avatar>
                              <div>
                                {{ moreCategory ? moreCategory.name : "" }}
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-tabs>
              </v-row>
            </div>
          </div>
          <v-container class="d-flex justify-center flex-wrap pa-0">
            <v-container
              class="pr-0 mr-0"
              style="
                max-height: 53vh !important;
                height: 53vh !important;
                overflow-y: auto;
              "
            >
              <v-row v-if="listView" class="d-flex justify-end ma-0">
                <v-col cols="12" sm="11" lg="10">
                  <v-card
                    text
                    v-for="(item, i) in categoryItems"
                    :key="i"
                    :disabled="!isSeller"
                    @click="itemSelected(item)"
                    @keypress.enter="itemSelected(item)"
                    :hover="!transactionStart"
                    :ref="`item${i}`"
                    class="mb-1"
                  >
                    <v-row :class="['ma-0', 'row']">
                      <v-col
                        style="min-width: 10px; max-width: 60%"
                        :class="[densityPadding, 'flex-grow-1']"
                        class="pr-0"
                      >
                        <div class="caption grey--text">Naziv artikla</div>
                        <div
                          class="one-liner-text smaller-font"
                          @mouseover="hoverOver(`hover-${item.id}`, item)"
                          @mouseleave="item[`hover-${item.id}`] = false"
                          :id="`hover-${item.id}`"
                          :ref="`hover-${item.id}`"
                        >
                          {{ item.name }}
                        </div>
                      </v-col>
                      <v-col
                        style="max-width: 15%"
                        :class="[densityPadding]"
                        class="pr-0 pl-0 mr-0 ml-0"
                      >
                        <div class="caption grey--text pr-0 pl-0 mr-0 ml-0">
                          Cijena artikla
                        </div>
                        <div>
                          <div class="smaller-font">
                            {{
                              item.price
                                ? $options.filters.money(
                                    item.price,
                                    100,
                                    currency
                                  )
                                : "Nije definirano"
                            }}
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        style="max-width: 15%"
                        class="pr-0 pl-0 mr-0 ml-0"
                        :class="[densityPadding]"
                      >
                        <div
                          class="caption grey--text text-sm-center text-md-left"
                        >
                          Šifra artikla
                        </div>
                        <div class="text-sm-center text-md-left smaller-font">
                          {{ item.code }}
                        </div>
                      </v-col>
                      <v-col
                        class="pr-0 pl-0 mr-0 ml-0"
                        style="max-width: 13%"
                        :class="[densityPadding]"
                      >
                        <div class="caption grey--text t">
                          Količina u paketu
                        </div>
                        <div class="smaller-font text-sm-center text-md-left">
                          {{ item.quantity_in_package / 1000 }}
                        </div>
                      </v-col>
                      <v-col
                        class="pr-0 pl-0 mr-0 ml-0"
                        style="max-width: 12%"
                        :class="[densityPadding]"
                      >
                        <div class="caption grey--text">Mjerna jedinica</div>
                        <div class="smaller-font">
                          {{ item.measure_unit }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else class="d-flex justify-center">
                <v-card
                  class="align-center ma-5"
                  v-for="(item, i) in categoryItems"
                  :key="i"
                  @click="itemSelected(item)"
                  @keypress.enter="itemSelected(item)"
                  :hover="!transactionStart"
                  :disabled="!isSeller"
                  height="300"
                  width="250"
                  :ref="`item${i}`"
                >
                  <v-img
                    height="210"
                    width="250"
                    v-if="item.imageUrl"
                    :src="item.imageUrl"
                  >
                  </v-img>
                  <v-img
                    height="210"
                    width="250"
                    v-else
                    src="@/assets/no-item.jpg"
                  >
                  </v-img>
                  <v-card-actions class="d-flex flex-column">
                    <h2 class="selling-point-title-text one-liner-text">
                      {{ $options.filters.capitalize(item.name) }}
                    </h2>
                    <!-- <h2 class="grey--text selling-point-text one-liner-text">{{$t('$vuetify.price')}}: {{item.prices[currency].price | money(100, currency)}}</h2> -->
                    <h2 class="grey--text selling-point-text one-liner-text">
                      {{ item.unitPrice | money(1, currency) }}
                    </h2>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-container>
          </v-container>
        </v-col>
        <v-col md="4" lg="3" class="mt-5" style="margin-top: -140px !important">
          <order-calculator
            ref="orderCalculator"
            :supplier="supplier"
            @transactionStarted="transactionStarted"
            @transactionEnded="transactionEnded"
          ></order-calculator>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import { auth, df } from '@/plugins/firebase'
import state from '@/state'
import OrderCalculator from '@/modules/point-of-sale/components/orders/other/receive-order/calculator/OrderCalculatorReceive'
import applicationSettings from '@/mixins/applicationSettings'
import EventBus from '@/plugins/event-bus'
import rules from '@/plugins/rules'

import barcodeChecker from '@/mixins/barcodeChecker' // don't move.. detaches firebase listeners
export default {
  name: 'CreateOrderOther',
  components: { OrderCalculator },
  mixins: [applicationSettings, barcodeChecker],
  inject: ['showMsgBox', 'confirm', 'confirmClose', 'showLoader', 'hideLoader', 'showMsgBoxHtml'],
  data: () => ({
    writeInProductDialog: false,
    transactionStart: false,
    suppliers: [
    ],
    loading: false,
    category: null,
    selectedCategory: undefined,
    categories: [],
    categoryItems: [],
    _categoryItems: [],
    order: undefined,
    selectedItem: undefined,
    currency: 'EUR',
    multiplier: 0,
    listeners: [],
    moreCategories: [],
    innerSearch: undefined,
    user: {},
    supplier: undefined,

    rules: {
      req: rules.req(),
      eq: rules.eq,
      oib: rules.oib(),
      arrReq: rules.arrReq()
    },
    expanded: false,
    location: undefined,
    barcode: []
  }),
  computed: {
    listView () {
      return state.isListView()
    },
    search () {
      return state.search
    },
    isSeller () {
      return state.getUser()?.roles?.PRODAVAČ
    },
    isAdmin () {
      return state.getUser()?.roles?.SUPERADMIN || state.getUser()?.roles?.ADMIN
    }
  },
  watch: {
    category (nv, ov) {
      if (nv !== ov) {
        this.selectCategory(this.categories[nv])
        this.tabChange()
      }
    },
    search () {
      this.searchItems()
    }
  },
  mounted () {
    this.order = this.$route.params.order
    this.location = this.$route.params.location

    this.supplier = this.order.supplier

    if (this.order.items && this.order.items.length > 0) {
      var newOrder = {
        items: this.order.items,
        supplier: this.supplier,
        orderData: this.order
      }
      EventBus.$emit('receive-all-items', newOrder)
    }

    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }

    EventBus.$on('set-mini', () => {
      this.expanded = !this.expanded
    })

    this.getMultiplier()
    if (!this.isSeller && !this.isAdmin) {
      this.showMsgBoxHtml({ text: this.$t('$vuetify.errors.isNotSeller'), actions: ['cancel'], cancelBtnText: this.$t('$vuetify.close'), color: 'error' })
    }

    try {
      this.$refs.item0[0].$el.focus()
    } catch {
      setTimeout(() => {
        if (this.$refs.item0) {
          this.$refs.item0[0].$el.focus()
        }
      }, 500)
    }
    this.loading = true

    this.selectedCategory = undefined
    this.categories = []
    this.getCategories(this.supplier.id)

    document.onkeydown = this.handleKeyEvents
  },
  beforeDestroy () {
    this.categoryItems = []
    this.categories = []
    this.detachListeners()
  },
  methods: {
    getProducts () {
      this.categoryItems = []
      this.getItems()
    },
    getCategories (supplierId) {
      this.listeners.push(df.doc(`suppliers/${supplierId}/categories/${supplierId}`).onSnapshot(doc => {
        const _categoreis = doc?.data()?.categories || []
        if (_categoreis.length === 0) return
        Object.keys(_categoreis).forEach(async (key, iterator) => {
          var name = _categoreis[key].name ? _categoreis[key].name : ''
          df.collection(`suppliers/${supplierId}/items`).where('category', '==', name)
            .limit(1)
            .get()
            .then((documentSnapshots) => {
              if (documentSnapshots.docs && documentSnapshots.docs.length > 0 && !documentSnapshots.docs.empty) {
                const _category = { ..._categoreis[key], [`hover-${_categoreis[key].id}`]: false }
                if (iterator < 6) this.categories.push(_category)
                if (iterator >= 6) this.moreCategories.push(_category)
              }
            })
        })

        if (this.categories) {
          this.categories.unshift({ name: 'SVE', picture: '', id: 'all', 'hover-all': false, status: 'OK' })
        }
      })
      )
    },
    showWriteInProductDialog () {
      this.writeInProductDialog = true
    },

    onDialogClose () {
      this.writeInProductDialog = false
    },

    async getUser () {
      const user = await df.doc(`users/${auth.currentUser.uid}`).get()
      return user
    },
    hoverOver (ref, item) {
      const container = this.$refs[ref]
      if (!container || !container[0]) return
      if (container[0].associateHeight < container[0].scrollHeight) {
        item[ref] = true
      }
    },
    changeView () {
      state.setListView(!state.isListView())
    },
    tabChange () {
      this.categoryItems = []
      this.getItems()
      setTimeout(() => {
        if (this.$refs.item0 && this.$refs.item0[0]) {
          this.$refs.item0[0].$el.focus()
        }
      }, 500)
    },

    selectCategory (category) {
      this.selectedCategory = category
    },
    itemSelected (item) {
      if (!this.transactionStart) {
        var order = {
          item: item,
          supplier: this.supplier,
          orderData: this.order
        }
        EventBus.$emit('receive-item', order)
      }
    },

    transactionStarted () {
      this.transactionStart = true
    },

    transactionEnded () {
      this.transactionStart = false
    },

    getItems () {
      if (!this.selectedCategory) {
        return
      }
      this.categoryItems = []

      const where = this.search ? this.search.toUpperCase() : ''

      let search = 'name'
      if (this.isBarcodeSearch(this.search)) {
        search = 'barcode'
      }
      let query = df.collection(`suppliers/${this.supplier.id}/items`)
        .orderBy(search, 'asc')

      if (!where) {
        query = query.limit(10)
      }

      // if (!where) {
      if (this.selectedCategory.id !== 'all') {
        query = query.where('category', '==', this.selectedCategory.name)
      }
      // }

      if (where) {
        query = query.where(search, '>=', where).where(search, '<=', where + '\uf8ff')
      }

      query
        .onSnapshot((doc) => {
          doc.docChanges().forEach((change) => {
            if (change.type === 'added') {
              if (!this.categoryItems.some(item => item.id === change.doc.data().id)) {
                const it = change.doc.data()

                df.doc(`suppliers/${this.supplier.id}/pricelists/${state.getCurrentCompany().id}/items/${it.id}`)
                  .get()
                  .then((itemDoc) => {
                    if (itemDoc && itemDoc.data()) {
                      var i = itemDoc.data()
                      it.price = i.price
                      it.pdv = i.pdv
                      it.margin = i.margin
                      this.categoryItems.push({ ...it, ...{ [`hover-${it.id}`]: false } })
                    } else {
                      this.categoryItems.push({ ...it, ...{ [`hover-${it.id}`]: false } })
                    }
                  })
              }
            }
            if (change.type === 'modified') {
              this.categoryItems = this.categoryItems.map(item => {
                let ret = item
                if (item.id === change.doc.data().id) {
                  ret = change.doc.data()
                }
                return { ...ret, ...{ [`hover-${ret.id}`]: false } }
              })
            }
          })
        })
    },
    innerSearchItems (reset = false) {
      if (reset) {
        state.search = ''
      } else {
        state.search = this.innerSearch
      }
    },
    searchItems (reset = false) {
      this.categoryItems = []

      this.getItems()
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    validateBarcode (b) {
      var Barcoder = require('barcoder')
      return Barcoder.validate(b)
    },
    barcodeFind (e) {
      if (e.key !== 'Enter') { this.barcode.push(e.key) }
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        if (this.validateBarcode(this.barcode.join('')) && this.supplier) {
          df.collection(`suppliers/${this.supplier.id}/items`)
            .where('barcode', '==', this.barcode.join(''))
            .orderBy('id', 'asc')
            .limit(1)
            .get()
            .then((documentSnapshots) => {
              if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
                documentSnapshots.docs.forEach((it) => {
                  var item = it.data()
                  df.doc(`suppliers/${this.supplier.id}/pricelists/${state.getCurrentCompany().id}/items/${item.id}`)
                    .get()
                    .then((itemDoc) => {
                      if (itemDoc && itemDoc.data()) {
                        var i = itemDoc.data()
                        item.price = i.price
                        item.pdv = i.pdv
                        item.margin = i.margin
                      }
                      var order = {
                        item: item,
                        supplier: this.supplier,
                        location: this.location
                      }
                      EventBus.$emit('add-other-order-item', order)
                    })
                }
                )
              }
            })
        }
        this.barcode = []
      }, 100)
    },
    handleKeyEvents (e) {
      e = e || window.event
      this.barcodeFind(e)
    }
  }
}
</script>
<style scoped>
.categories {
  position: sticky;
  position: -webkit-sticky; /*  for Safari */
  top: 64px;
  padding-bottom: 0px;
  z-index: 3;
  width: 100%;
}

.categories >>> .v-tabs-bar__content {
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 0px;
}

.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0.1px;
  z-index: 4;
  width: 100%;
}
.status-ok {
  border-left: 4px solid var(--v-success-base);
}

.status-failed {
  border-left: 4px solid var(--v-error-base);
}
.theme--light.v-tabs >>> .v-tabs-bar {
  background-color: rgb(256, 256, 256, 1) !important;
}

.theme--dark.v-tabs >>> .v-tabs-bar {
  background-color: rgb(18, 18, 18, 1) !important;
}

.ai-app-menu {
  /* border: none;
  width: 100%;
  height: 100%; */
  height: 300px;
  width: 340px;
  overflow: auto;
  overflow-x: hidden;
}

.more-category {
  cursor: pointer;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.smaller-font {
  font-size: 12px;
}
</style>
