<template>
  <v-row
    style="margin-top: 2px; margin-bottom: -2rem"
    class="d-flex justify-center"
  >
    <!-- <v-col cols="4" sm="4" md="3" xl="2">
          <v-select
            label="Status narudžbe"
            v-model="statusFilter"
            :items="filters"
            return-object
            item-text="name"
            item-value="value"
          >
            <template v-slot:item="{ item }">
              {{ item.name }}
            </template>
          </v-select>
        </v-col> -->

    <v-col cols="12" sm="11" lg="11" :class="['px-0']">
      <v-expansion-panels focusable>
        <v-expansion-panel
          v-for="(order, i) in orders"
          :key="i"
          hover
          tabindex="0"
        >
          <v-expansion-panel-header height="200" :class="[]" hide-actions>
            <v-row>
              <v-col
                cols="4"
                sm="4"
                md="2"
                lg="2"
                xl="2"
                :class="[densityPadding]"
              >
                <div class="caption grey--text text-center">Datum narudžbe</div>
                <div class="text-center">{{ order.created | local }}</div>
              </v-col>
              <v-col
                cols="4"
                sm="4"
                md="2"
                lg="2"
                xl="2"
                :class="[densityPadding]"
              >
                <div class="caption grey--text text-center">Dobavljač</div>
                <div class="text-center">{{ order.supplier.name }}</div>
              </v-col>
              <v-col
                cols="4"
                sm="4"
                md="2"
                lg="2"
                xl="2"
                :class="[densityPadding, 'flex-shrink-0']"
                v-if="admin"
              >
                <div class="caption grey--text text-right">Iznos narudžbe</div>
                <div class="text-right">
                  {{ order.total ? order.total : "Nije definirano" }}
                </div>
              </v-col>
              <v-col
                cols="4"
                sm="4"
                md="2"
                lg="2"
                xl="2"
                :class="[densityPadding]"
              >
                <div class="caption grey--text text-center">
                  Predviđen datume dostave
                </div>
                <div class="text-center">
                  {{

                    showDeliveryDate(order.delivery_date)

                  }}
                </div>
              </v-col>
              <v-col
                cols="4"
                sm="4"
                md="2"
                lg="2"
                xl="2"
                :class="[densityPadding]"
              >
                <div class="d-flex justify-space-between">
                  <div>
                    <div class="caption grey--text text-left">
                      Status narudžbe
                    </div>
                    <div class="text-left">{{ showStatus(order.status) }}</div>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="4"
                sm="4"
                md="2"
                lg="2"
                xl="2"
                :class="[densityPadding]"
              >
                <div class="d-flex justify-space-between">
                  <div>
                    <div class="caption grey--text text-left">Naručio</div>
                    <div class="text-left">
                      {{ $options.filters.capitalize(order.created_by_name) }}
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="5"
                sm="4"
                md="2"
                lg="2"
                xl="2"
                :class="[densityPadding]"
              >
                <div class="text-center mr-3">
                  <v-btn width="160"
                    @click.prevent.stop="openOrder(order)"
                    >{{ order.status == 'CREATED' ? 'Kreiraj primku' : 'Ponovno kreiraj' }}</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" sm="5">
                <div
                  class="
                    d-flex
                    justify-space-between
                    text-center text-subtitle-2
                  "
                >
                  Način plaćanja: {{ order.payment_method }}
                </div>
              </v-col>
            </v-row>
            <v-list>
              <v-row>
                <v-col>
                  <div
                    class="
                      text-center text-subtitle-2
                      one-liner-text
                      font-weight-bold
                    "
                  >
                    R. br.
                  </div>
                </v-col>
                <v-col>
                  <div
                    class="
                      text-center text-center text-subtitle-2
                      one-liner-text
                      font-weight-bold
                    "
                  >
                    Naziv
                  </div>
                </v-col>
                <v-col>
                  <div
                    class="
                      text-center text-center text-subtitle-2
                      one-liner-text
                      font-weight-bold
                    "
                  >
                    Količina
                  </div>
                </v-col>
                <v-col>
                  <div
                    class="
                      text-center text-center text-subtitle-2
                      one-liner-text
                      font-weight-bold
                    "
                  >
                    Jedinična cijena
                  </div>
                </v-col>
                <v-col>
                  <div
                    class="
                      text-center text-center text-subtitle-2
                      one-liner-text
                      font-weight-bold
                    "
                  >
                    Cijena
                  </div>
                </v-col>
              </v-row>
              <v-list-item
                v-for="(item, index) in order.items"
                :key="index"
                class="pa-0"
              >
                <v-row class="pa-0">
                  <v-col>
                    <div class="text-center text-subtitle-2">
                      {{ index + 1 }}
                    </div>
                  </v-col>
                  <v-col>
                    <div class="text-center text-subtitle-2">
                      {{ item.name }}
                    </div>
                  </v-col>
                  <v-col>
                    <div class="text-center text-subtitle-2">
                      {{ item.quantity / multiplier }}
                    </div>
                  </v-col>
                  <v-col>
                    <div class="text-center text-subtitle-2">
                      {{ item.price | money(100, currency) }}
                    </div>
                  </v-col>
                  <v-col>
                    <div class="text-center text-subtitle-2">
                      {{
                        (item.price * (item.quantity / multiplier))
                          | money(100, currency)
                      }}
                    </div>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <infinite-loading
      ref="InfiniteLoading"
      @infinite="infiniteHandler"
      spinner="waveDots"
    >
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </v-row>
</template>
<script>
import rules from '@/plugins/rules'
import { df } from '@/plugins/firebase'
import state from '@/state'
import InfiniteLoading from 'vue-infinite-loading'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings'
import { clone } from '@/plugins/utils'
import EventBus from '@/plugins/event-bus'

export default {
  components: {
    InfiniteLoading
  },
  mixins: [flow, applicationSettings],
  inject: [
    'showMsgBox',
    'showLoader',
    'hideLoader',
    'openPdfPrint',
    'closePdfPrint'
  ],
  data: function () {
    return {
      admin: false,
      loading: false,
      // location: undefined,
      orders: [],
      associates: [],
      statusFilter: {},
      lastVisible: {},
      listeners: [],
      selected: {},
      warehouse: undefined,
      multiplier: 0,
      show: false,
      rules: {
        req: rules.req(),
        oib: rules.oib()
      },
      currency: 'EUR',
      targetModifier: 'company'
    }
  },
  props: {
    supplier: {
      type: Object,
      default: () => {}
    },
    location: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    now () {
      return parseInt((`${new Date().getTime()}` / 1000).toFixed(0))
    },
    filters () {
      return [
        { name: 'Dostavljeno', value: 5 },
        { name: 'U dostavi', value: 4 },
        { name: 'U pripremi', value: 3 },
        { name: 'Zaprimljeno', value: 2 }
      ]
    }
  },
  watch: {
    location (nv) {
      if (nv) {
        this.getWarehouse()
      }
    }
  },
  mounted () {
    var sideNav = this.$route.meta.sideNav
    switch (sideNav) {
      case 'Companies':
        break
      case 'Company':
        this.targetModifier = 'company'
        break
      case 'PointOfSale':
        this.targetModifier = 'pointOfSale'
        break
      case 'CashRegister':
        this.targetModifier = 'pointOfSale'
        break
    }

    EventBus.$on('resetCreatedOrders', reset => this.reset())
    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }
    this.admin = state.getUser()?.roles?.SUPERADMIN || state.getUser()?.roles?.ADMIN
    this.admin = !this.admin

    this.getMultiplier()

    this.orders = []
  },
  methods: {
    showDeliveryDate (deliveryDate) {
      if (deliveryDate && deliveryDate > 0) {
        return this.$options.filters.local(deliveryDate)
      } else {
        return 'Nije definirano'
      }
    },
    reset () {
      this.lastVisible = {}
      this.orders = []
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },
    showStatus (status) {
      if (status) {
        switch (status) {
          case 'CREATED':
            return 'Naručeno'
          case 'DELIVERY_CREATED':
            return 'Primka kreirana'
        }
      } else {
        return 'Naručeno'
      }
    },
    async getWarehouse () {
      this.warehouse = undefined
      let warehouse = {}
      const query = df
        .collection('warehouses/')
        .where('company_id', '==', state.getCurrentCompany().id)
        .where('selling_warehouse', '==', true)
        .where('status', '==', 'OK')
        .where('location_id', '==', this.location.id)

      await query.get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            warehouse = doc.data()
          })
        })

      this.warehouse = warehouse

      this.orders = []
      this.$refs.InfiniteLoading.stateChanger.reset()
    },
    deliveryNoteDone () {
      this.$refs.InfiniteLoading.stateChanger.reset()
    },
    async checkDeliveryNote (orders) {
      const orderIds = []
      if (orders !== undefined && orders.length > 0) {
        orders.forEach(order => {
          orderIds.push(order.orderId.toString())
        })
      }

      if (orderIds !== undefined && orderIds.length > 0) {
        const query = await df.collection('delivery_notes')
          .where('id', 'in', orderIds)
          .get()

        const deliveryNotes = query.docs.map(doc => doc.data())

        if (deliveryNotes !== undefined && deliveryNotes.length > 0) {
          deliveryNotes.forEach(note => {
            this.orders.forEach(order => {
              if (order.orderId === parseInt(note.id)) {
                order.allowDelivery = false
              }
            })
          })
        }
        this.$forceUpdate()
      }
    },
    openOrder (order) {
      this.$router.push({
        name: this.targetModifier + '.orders.receiveOrder',
        params: {
          order: order,
          location: this.location
        }
      })
    },
    showOrderStatus (status) {
      switch (status) {
        case 2:
          return 'Zaprimljeno'
        case 3:
          return 'U pripremi'
        case 4:
          return 'U dostavi'
        case 5:
          return 'Dostavljeno'
        default:
          return 'Nepoznat status'
      }
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },

    infiniteHandler ($state) {
      const that = this
      // if (this.supplier === undefined || this.supplier === '' || this.warehouse === undefined) {
      if (this.warehouse === undefined) {
        $state.complete()
        return
      }

      if (!this.supplier) {
        var query = df.collection('supplier_orders')
          .where('location_details.id', '==', this.warehouse.location.id)
          .where('status', '==', 'CREATED')
          .orderBy('created', 'desc')
          .startAfter(that.lastVisible)
          .limit(10)
      } else {
        query = df.collection(`warehouses/${that.warehouse.id}/supplier_orders`)
          .where('supplier_id', '==', this.supplier.id)
          .orderBy('created', 'desc')
          .startAfter(that.lastVisible)
          .limit(10)
      }

      const listener = query
        .onSnapshot((doc) => {
          doc.docChanges().forEach(change => {
            if (change.type === 'added') {
              if (change.doc.data()) {
                const _document = clone(change.doc.data())

                this.orders.push({
                  ..._document,
                  ...{ [`hover-${change.doc.data().id}`]: false }
                })
                // }

                this.orders.sort((a, b) => b.created - a.created) // b - a for reverse sort
                this.$forceUpdate()
              }
            }
            if (change.type === 'modified') {
              // TODO: duplicated code, make 1 fun
              this.orders = this.orders.map(item => {
                let ret = item
                if (item.id === change.doc.data().id) {
                  const _document = clone(change.doc.data())

                  ret = _document
                }
                return { ...ret, ...{ [`hover-${change.doc.data().id}`]: false } }
              })
            }
          })
          if (doc && !doc.empty) {
            if (doc.docs[doc.docs.length - 1]) {
              that.lastVisible = doc.docs[doc.docs.length - 1]
            }
            $state.loaded()
          } else {
            $state.complete()
          }
        })

      this.listeners.push(listener)
    }
  }

}
</script>
<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}
tr:hover {
  cursor: pointer;
}
</style>

<style>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
