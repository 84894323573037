<template>
  <div>
    <div class="pb-0 mb-0">
      <v-container class="pb-0 mb-0">
        <v-col
          class="d-flex justify-end"
          height="30px"
          style="margin-top: 10px"
        >
          <v-btn
            class="white--text loginButton"
            :disabled="nextDisabled"
            @click="openConfirmDialog()"
          >
            {{ $t("$vuetify.order").split("|")[0] }}
          </v-btn>
        </v-col>
        <v-row v-if="totalWithDiscount === 0" class="pb-0 mb-0">
          <v-col class="pb-0 mb-0">
            <p class="font-weight-bold pb-0 mb-0">{{ $t("$vuetify.total") }}</p>
          </v-col>
          <v-col class="pb-0 mb-0">
            <p class="font-weight-bold pb-0 mb-0">
              {{ total | money(1, currency) }}
            </p>
          </v-col>
        </v-row>

        <!-- ISPIS KONVERZIJE U EURO -->
        <v-row
          class="mt-0 pt-0 pb-0 mb-0"
          v-if="totalWithDiscount === 0 && currency === 'EUR'"
        >
          <v-col class="mt-0 pt-0 pb-0 mb-0">
            <p class="font-weight-bold mt-0 pt-0"></p>
          </v-col>
          <v-col class="mt-0 pt-0 pb-0 mb-0">
            <p class="font-weight-bold mt-0 pb-0 mb-0">
              {{ eurTotal | money(1, "EUR") }}
            </p>
          </v-col>
        </v-row>
        <v-row
          class="mt-0 pt-0 pb-0 mb-0"
          v-if="totalWithDiscount === 0 && currency === 'HRK'"
        >
          <v-col class="mt-0 pt-0 pb-0 mb-0">
            <p class="font-weight-bold mt-0 pt-0"></p>
          </v-col>
          <v-col class="mt-0 pt-0 pb-0 mb-0">
            <p class="font-weight-bold mt-0 pb-0 mb-0">
              {{ hrkTotal | money(1, "HRK") }}
            </p>
          </v-col>
        </v-row>
        <v-row
          v-if="
            totalWithDiscount === 0 &&
            (currency === 'EUR' || currency === 'HRK')
          "
          class="pt-0 mt-0 pb-0 mb-0"
        >
          <v-col class="mt-0 pt-1 pl-3 pb-0 mb-0">
            <p class="mt-0" style="font-size: 12px">
              {{ "Tečaj 1 EUR = 7,53450 HRK" }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div>
      <v-col class="d-flex justify-end pt-0 pb-0 mb-0" height="30px">
        <v-text-field
          dense
          class="pt-0 mt-0 pb-0 mb-0"
          outlined
          hide-details
          v-if="items.length > 0"
          label="Pretraži košaricu"
          v-model="cartFind"
          @change="searchCart()"
        >
        </v-text-field>
      </v-col>
    </div>
    <v-simple-table
      :height="items.length > 0 ? `57vh` : 50"
      class="dynamicTable"
    >
      <template v-slot:default>
        <tbody>
          <v-list v-if="items.length > 0" dense class="py-0">
            <template v-for="(item, i) in items">
              <div :key="i" :class="{ 'deletable-div ': hover === i }">
                <v-divider v-if="i > 0"></v-divider>

                <v-list-item dense>
                  <v-avatar :size="$vuetify.breakpoint.mdAndUp ? '55' : '45'">
                    <v-img
                      v-if="item.imageUrl"
                      :src="item.imageUrl"
                      contain
                    ></v-img>
                    <v-img v-else src="@/assets/no-item.jpg"></v-img>
                  </v-avatar>
                  <v-list-item-content
                    class="item-details align-start pl-3 d-flex justify-center"
                    style="max-width: 60% !important"
                  >
                    <div class="font-weight-bold pl-1 text-subtitle mt-1">
                      {{ item.articleName }}
                    </div>
                    <v-list-item-subtitle
                      class="pl-1"
                      v-if="
                        item.unitPriceWithDiscount === undefined ||
                        item.unitPriceWithDiscount === 0
                      "
                    >
                      {{ item.unitPrice | money(1) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="pl-1"
                      v-if="
                        item.unitPriceWithDiscount > 0 &&
                        item.unitPriceWithDiscount > item.unitPrice
                      "
                    >
                      <s>{{ item.unitPriceWithDiscount | money(1) }}</s>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="pl-1"
                      v-if="item.unitPriceWithDiscount > 0"
                    >
                      {{ item.unitPriceWithDiscount | money(1) }}
                    </v-list-item-subtitle>
                    <div :style="`width: ${priceInputWidth}`">
                      <quantity-input
                        dense
                        v-model="item.quantity"
                        autocomplete="off"
                        class="centered-input"
                        :val="item.quantity"
                        ref="quantityInput"
                        @change="changedQuantity(item)"
                      >
                        <template v-slot:prepend>
                          <v-icon
                            color="red"
                            hover
                            dense
                            @click="reduceQuantity(item)"
                          >
                            mdi-minus
                          </v-icon>
                        </template>
                        <template v-slot:append-outer>
                          <v-icon
                            color="green"
                            hover
                            dense
                            @click="increaseQuantity(item)"
                          >
                            mdi-plus
                          </v-icon>
                        </template>
                      </quantity-input>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="i == items.length - 1"></v-divider>
              </div>
            </template>
          </v-list>
          <v-container v-else class="d-flex justify-center align-center">
            <span class="text-title">Dodajte artikle u narudžbu</span>
          </v-container>
        </tbody>
      </template>
    </v-simple-table>
    <order-confirm ref="orderConfirmDialog"></order-confirm>
  </div>
</template>
<script>
import { df, functions } from '@/plugins/firebase'
import EventBus from '@/plugins/event-bus'
import QuantityInput from '@/components/QuantityInput'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import OrderConfirm from '@/modules/point-of-sale/components/orders/roto/create-order/calculator/OrderConfirm.vue'
import state from '@/state'

export default {
  props: ['data'],
  mixins: [applicationSettings],
  components: { QuantityInput, OrderConfirm },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: function () {
    return {
      hover: -1,
      items: [],
      oldItems: [],
      orderCart: undefined,
      currencies: [],
      total: 0,
      currency: undefined,
      totalWithDiscount: 0,
      basketDiscount: undefined,
      listener: [],
      totalShoppingCart: undefined,
      totalWithoutDiscounts: undefined,
      cartFind: undefined,
      realItems: []
    }
  },
  computed: {
    priceInputWidth () {
      return this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.smAndDown ? '50%' : '100%'
    },
    nextDisabled () {
      return !this.total || !this.allQuantitiesSet()
    },
    eurTotal () {
      const m = 100.00
      const inFloat = this.total.toFixed(2)
      const trueVal = inFloat / 100
      const tConv = trueVal.toFixed(2) / 7.53450
      const tOut = Math.round(tConv * m)
      return tOut.toFixed(2)
    },
    eurTotalDiscount () {
      const m = 100.00
      const inFloat = this.totalWithDiscount.toFixed(2)
      const trueVal = inFloat / 100
      const tConv = trueVal.toFixed(2) / 7.53450
      const tOut = Math.round(tConv * m)
      return tOut.toFixed(2)
    },
    hrkTotal () {
      const m = 100.00
      const inFloat = this.total.toFixed(2)
      const trueVal = inFloat / 100
      const tConv = trueVal.toFixed(2) * 7.53450
      const tOut = Math.round(tConv * m)
      return tOut.toFixed(2)
    }
  },
  watch: {
    data (val) {
      if (!val || !val.items) {
        this.items = []
      }
    },
    totalWithDiscount (val) {
    },
    cartFind (val) {
      if (val === '') {
        this.items = this.realItems
      }
    }
  },
  mounted () {
    this.getMultiplier()
    this.currencies = [
      'HRK', 'EUR', 'USD', 'GBP'
    ]
    this.currency = 'HRK'
    EventBus.$on('add-order-item', item => this.addItem(item))
    EventBus.$on('roto-location', location => this.setLocation(location))
    EventBus.$on('supplier-selected', supplier => this.getCart(supplier))
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  },
  methods: {
    setLocation (location) {
      this.location = location
    },
    openConfirmDialog () {
      this.orderCart.products = this.items
      this.$refs.orderConfirmDialog.open(this.orderCart)
    },
    clearCartFind () {
      this.cartFind = ''
    },
    searchCart () {
      if (this.cartFind !== '') {
        this.realItems = this.items
        const tmp = []
        this.items.forEach(item => {
          if (item.articleName.includes(this.cartFind.toUpperCase())) {
            tmp.push(item)
          }
        })
        this.items = tmp
      }
    },
    changedQuantity (item) {
      let diff = item.quantity - item.quantityOld
      if (diff === 0) {
        diff = -item.quantity
      }

      this.items.forEach((it, key) => {
        if (item.articleID === it.articleID) {
          this.items[key].quantityOld = it.quantity
        }
      })

      var params = {
        supplier: 'roto',
        item_id: item.articleID,
        quantity: diff
      }

      this.callHttps(params)
    },
    async callHttps (params) {
      this.showLoader()
      params.company_id = state.getCurrentCompany().id
      params.location_id = state.getCurrentCompany().id

      var ordersApi = functions.httpsCallable('rotoorders')
      await ordersApi({
        action: {
          operation: 'update',
          entity: 'order',
          params: params
        },
        attributes: {
        }
      })
        .then((result) => {
          if (result.data !== undefined && result.data.data !== undefined) {
            this.orderCart = result.data.data
          }

          if (this.orderCart !== undefined) {
            if (this.orderCart.products !== undefined && this.orderCart.products.length > 0) {
              this.items = this.orderCart.products
              this.items.forEach(item => {
                item.quantityOld = item.quantity
              })

              this.realItems = this.items
              this.clearCartFind()
            }

            this.total = this.orderCart.totalOrderPrice
            this.hideLoader()
          }
        })
    },
    getCart (supplier) {
      var ordersApi = functions.httpsCallable('rotoorders')

      const params = {
        supplier: 'roto',
        company_id: state.getCurrentCompany().id,
        location_id: this.location.id
      }

      ordersApi({
        action: {
          operation: 'init',
          entity: 'order',
          params: params
        },
        attributes: {
        }
      })
        .then((result) => {
          if (result.data !== undefined && result.data.data !== undefined) {
            this.orderCart = result.data.data
          }

          if (this.orderCart !== undefined) {
            if (this.orderCart.products !== undefined && this.orderCart.products.length > 0) {
              this.items = []
              this.orderCart.products.forEach(product => {
                product.quantityOld = product.quantity
                this.items.push(product)
              })
            }

            this.total = this.orderCart.totalOrderPrice
          }

          this.total = this.orderCart.totalOrderPrice
        }
        )
    },
    capitalizeFirst (name) {
      if (name !== undefined && name !== null && name.length > 2) {
        return name.charAt(0).toUpperCase() + name.slice(1)
      }
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    advanceStep (type) {
      this.items = this.items.map(item => {
        const ret = item
        ret.quantity = parseFloat(item?.quantity?.toString()?.replace(',', '.')) || 0
        return ret
      })

      this.$emit('advanceStep', { items: this.items, currency: this.currency, total: this.total, type: type, title: type === 'P' ? this.$t('$vuetify.offer').split('|')[0] : this.$t('$vuetify.receipt').split('|')[0], totalWithDiscount: this.totalWithDiscount, fullTotal: this.totalWithoutDiscounts, totalShoppingCart: this.totalShoppingCart, discount: this.basketDiscount })
    },
    setCurrency () {
      this.$emit('currencyChanged', this.currency)
      this.items = []
    },
    addItem (item) {
      // if (this.items.some(it => it.articleName === item.articleName)) {
      //   this.items.map(it => {
      //     if (it.articleName === item.articleName) {
      //       it.quantity = parseFloat(it.quantity.toString().replace(',', '.')) + 1.000
      //       it.quantity = it.quantity.toFixed(3).replace('.', ',')

      //       // TODO dodati ubacivanje ordera u item
      //       // it.choose
      //     }
      //     return it
      //   })
      // } else {
      //   this.items.push({ ...item, ...{ quantity: 1.000.toFixed(3).replace('.', ',') } })
      // }

      var params = {
        supplier: 'roto',
        item_id: item.articleID,
        quantity: item.unitQuantity
      }

      this.callHttps(params)
    },

    reduceQuantity (item) {
      //       this.items = this.items.map(it => {
      //   if (it.name === item.name && it.prices[this.currency].price === item.prices[this.currency].price) {
      //     // const decimals = it.quantity.toString().split(/[.|,]/)
      //     // let decimalPlaces = 0
      //     // if (decimals[1]) decimalPlaces = decimals[1].length
      //     it.quantity = it.quantity.toString().replace(',', '.') - 1 >= 0 ? it.quantity.toString().replace(',', '.') - 1 : 0
      //     // if (decimalPlaces) { it.quantity = it.quantity.toString().substr(0, decimals[0].length + decimals[1].length + 1) }
      //     if (it.quantity > 0) {
      //       it.quantity = it.quantity.toFixed(3).replace('.', ',')
      //     }
      //   }
      //   return it
      // })
      // this.items = this.items.filter(it => it.quantity)

      var params = {
        supplier: 'roto',
        item_id: item.articleID,
        quantity: -item.unitQuantity
      }

      this.callHttps(params)
    },

    increaseQuantity (item) {
      // this.items = this.items.map(it => {
      //   if (it.articleName === item.articleName) {
      //     it.quantity = parseFloat(it.quantity.toString().replace(',', '.')) + 1.000
      //     it.quantity = it.quantity.toFixed(3).replace('.', ',')
      //   }
      //   return it
      // })

      var params = {
        supplier: 'roto',
        item_id: item.articleID,
        quantity: item.unitQuantity
      }

      this.callHttps(params)
    },
    removeItemFromBasket (item) {
      this.items = this.items.filter(it => it !== item)
      this.hover = -1
    },
    allQuantitiesSet () {
      if (!this.items || !Array.isArray(this.items)) return false
      return this.items.every(item => this.isCorrectQuantity(item.quantity))
    },
    isCorrectQuantity (quantity) {
      const priceRx = /^([0-9]+)$|^(([0-9]+)[,|.]{1}([0-9]+))$/
      return quantity.toString().match(priceRx)
    }
  }
}
</script>
<style scoped>
.deletable-div {
  opacity: 0.6;
}
.deletable-div .delBtn {
  font-size: 38px !important;
}
.item-details {
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.dynamicTable {
  overflow: auto;
}

.centered-input >>> input {
  text-align: center;
}

.deletable-div .minBtn {
  font-size: 38px !important;
}

.deletable-div .disBtn {
  font-size: 38px !important;
}
</style>
