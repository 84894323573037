<template>
  <v-dialog
    v-model="dialogOpen"
    transition="dialog-bottom-transition"
    fullscreen
    hide-overlay
    @keydown.esc="close"
  >
    <v-overlay v-show="loading" absolute z-index="10">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-card
      class="pb-5"
      style="overflow-x: hidden"
      :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
    >
      <v-card-title class="d-flex" style="vertical-align: middle">
        <v-row class="pa-3 mb-2">
          <h3 class="text--secondary">Podaci za primku</h3>
          <v-spacer></v-spacer>
          <v-icon large @click="close">mdi-close</v-icon>
        </v-row>
      </v-card-title>
      <v-container
        fluid
        class="d-flex justify-center align-center flex-wrap pa-0 mt-0 fill-height"
      >
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="11"
            lg="11"
            :class="['px-0']"
            v-if="order !== undefined"
          >
            <v-row class="pa-0 ma-0">
              <h4 class="pt-3">
                Adresa:
                {{
                  order &&
                  order.orderData &&
                  order.orderData.location_details &&
                  order.orderData.location_details.name
                    ? order.orderData.location_details.name
                    : ""
                }}
              </h4>
              <h4 class="pl-2 pr-2 pt-3" v-if="user">
                Naručio:
                {{ user.name.toUpperCase() + " " + user.surname.toUpperCase() }}
              </h4>

              <h4 class="pt-3">
                Način plaćanja:
                {{
                  order && order.orderData && order.orderData.payment_method
                    ? order.orderData.payment_method
                    : ""
                }}
              </h4>
              <v-text-field
                hide-details
                dense
                class="mt-0 pt-2 mb-0 ml-2"
                style="max-width: 12% !important; font-weight: bold"
                label="Oznaka primke"
                v-model="deliveryNumber"
              ></v-text-field>
            </v-row>
            <br />
            <v-data-table
              v-if="
                order !== undefined &&
                order.items !== undefined &&
                order.items.length > 0
              "
              :headers="orderHeaders"
              :items="order.items"
              :items-per-page="-1"
              :search="searchTable"
              height="58vh"
              fixed-header
              dense
              class="elevation-1 pb-5 mb-5"
              hide-default-footer
              :custom-filter="filterOnlyCapsText"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Proizvodi</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="searchTable"
                    label="Pretraži proizvode"
                    class="mt-4"
                  ></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td></td>
                  <td>{{ item.code }}</td>
                  <td>{{ item.name }}</td>
                  <!-- <td>{{ item.price | money(100, currency) }}</td> -->

                  <td>
                    <v-text-field
                      class="pa-0 ma-0 mb-5 pb-4 pr-20"
                      dense
                      v-model="item.price"
                      style="
                        height: 12px !important;
                        width: 80px;
                        font-size: 14px;
                      "
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="pa-0 ma-0 mb-5 pb-4 pr-20"
                      dense
                      v-model="item.margin"
                      style="
                        height: 12px !important;
                        width: 80px;
                        font-size: 14px;
                      "
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="pa-0 ma-0 mb-5 pb-4 pr-20"
                      dense
                      v-model="item.pdv"
                      style="
                        height: 12px !important;
                        width: 80px;
                        font-size: 14px;
                      "
                    ></v-text-field>
                  </td>
                  <td>{{ item.quantity_in_package }}</td>
                  <td>
                    <v-text-field
                      class="pa-0 ma-0 mb-5 pb-4 pr-20"
                      dense
                      v-model="item.quantity"
                      style="
                        height: 12px !important;
                        width: 80px;
                        font-size: 14px;
                      "
                      @change="changedQuantity(item)"
                    ></v-text-field>
                  </td>
                </tr>
              </template>
            </v-data-table>

            <div class="text-right">
              <h4>
                Iznos narudžbe:
                {{ total }}
              </h4>
              <br />
              <v-btn
                right
                bottom
                class="white--text loginButton mr-2"
                @click="close"
              >
                Uredi
              </v-btn>
              <v-btn
                right
                bottom
                class="white--text loginButton"
                @click="finishOrder()"
              >
                Kreiraj primku
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-overlay v-if="loading" absolute>
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>

import rules from '@/plugins/rules'
import { df, auth } from '@/plugins/firebase'
import state from '@/state'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings'
import { v4 as uuidv4 } from 'uuid'
import { clone } from '@/plugins/utils'

export default {
  components: {
  },
  mixins: [flow, applicationSettings],
  inject: [
    'showMsgBox',
    'showLoader',
    'hideLoader',
    'openPdfPrint',
    'closePdfPrint'
  ],
  data: function () {
    return {
      dialogOpen: false,
      loading: false,
      currency: 'EUR',
      order: {},
      user: undefined,
      multiplier: 0,
      searchTable: undefined,
      show: false,
      locations: [
      ],
      location: undefined,
      paymentTypes: [{
        text: 'Gotovina',
        value: 'GOTOVINA'
      }, {
        text: 'Kartica',
        value: 'KARTICA'
      }
      ],
      paymentType: undefined,
      rules: {
        req: rules.req(),
        oib: rules.oib()
      },
      orderHeaders: [
        {
          text: '',
          value: 'imageUrl'
        },
        {
          text: 'Šifra artikla',
          value: 'code'
        },
        {
          text: 'Naziv artikla',
          value: 'name'
        },
        {
          text: 'Cijena artikla',
          value: 'price'
        },
        {
          text: 'Rabat',
          value: 'margin'
        },
        {
          text: 'PDV',
          value: 'pdv'
        },
        {
          text: 'Količina u paketu',
          value: 'quantity_in_package'
        },
        {
          text: 'U narudžbi',
          value: 'quantity'
        }
      ],
      warehouse: undefined,
      supplier: undefined,
      deliveryNumber: undefined
    }
  },
  computed: {
    now () {
      return parseInt((`${new Date().getTime()}` / 1000).toFixed(0))
    },
    total () {
      var allHavePrice = true
      let tempTotal = 0
      if (this.order && this.order.items && this.order.items.length > 0) {
        this.order.items.forEach(item => {
          var price = this.formatNumber(item.price)
          if (item.price && price > 0) {
            tempTotal += (price * this.formatNumber(item.quantity, 1))
          } else {
            allHavePrice = false
          }
        })
      } else {
        return 0
      }

      if (allHavePrice === true) {
        return tempTotal / 100
      } else {
        return 0
      }
    }
  },
  mounted () {
    this.user = state.getUser()
    this.getMultiplier()
  },
  created () {
    const docRef = df.doc(
      `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
    )
    const listener = docRef.onSnapshot(doc => {
      if (
        doc &&
        doc.data() &&
        doc.data().locations &&
        Object.keys(doc.data().locations).length > 0
      ) {
        this.locations = Object.keys(doc.data().locations).map(key => {
          return doc.data().locations[key]
        })
      }
    })
    this.listeners.push(listener)
  },
  methods: {
    formatNumber (num, multiplier = 100) {
      if (!num || num === '' || num === 0) return 0

      var bla = num.toString().trim()
      if (bla === '') return 0

      var number = bla.replace(/,/g, '.')
      number = parseFloat(number)
      return parseInt(number * multiplier)
    },
    async getWarehouse () {
      let warehouse = ''
      const query = df
        .collection('warehouses/')
        .where('company_id', '==', state.getCurrentCompany().id)
        .where('selling_warehouse', '==', true)
        .where('status', '==', 'OK')
        .where('location_id', '==', this.location.id)

      await query.get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            warehouse = doc.data()
          })
        })

      this.warehouse = warehouse
    },
    async finishOrder () {
      const reqId = uuidv4()

      if (!this.supplier || !this.warehouse || !this.location) {
        return
      }

      this.loading = true

      const extendedItems = []
      var ordItems = clone(this.order.items)
      ordItems.forEach(item => {
        const itemId = uuidv4()

        var mu = item.measure_unit
        var qp = this.formatNumber(item.quantity_in_package, 1)
        var qu = this.formatNumber(item.quantity, 1)
        if (qp > 1) {
          mu = 'Gajba'
          qu = qu / qp
          qu *= this.multiplier
        } else {
          qu *= this.multiplier
        }
        var pp = 0
        if (item.price) {
          pp = this.formatNumber(item.price, 100) * (this.formatNumber(item.quantity, 1))
        }

        var name = item.name
        if (item.item_name && item.item_name !== '') {
          name = item.item_name + ' ' + item.quantity_unit
        }

        const newItem = {
          id: itemId,
          item: {
            name: name,
            code: item.code,
            barcode: item.barcode,
            description: item.description,
            picture: item.imageUrl ? item.imageUrl : '',
            id: itemId,
            selling_unit: item.measure_unit,
            status: 'OK',
            storage_unit: item.measure_unit,
            type: 'GOODS',
            stock: this.formatNumber(item.quantity, 1) * this.multiplier
          },
          warehouse_data: {
            ean: item.barcode,
            item_id: itemId,
            item_key: '',
            item_name: name,
            location_key: this.warehouse?.location.id ? this.warehouse?.location?.id : '',
            manufacturer_item_key: item.code,
            margin: 0,
            margin_amount: item.margin && item.margin > 0 ? this.formatNumber(item.margin) : 0,
            measure_unit: item.measure_unit,
            partner_item_key: item.code,
            pretax: 0,
            pretax_amount: 0,
            purchase_price: item.price * 100,
            quantity: this.formatNumber(item.quantity, 1) * this.multiplier,
            quantity_on_hand: this.formatNumber(item.quantity, 1) * this.multiplier,
            quantity_on_order: 0,
            rebate_amount: 0,
            retail_price: 0,
            sale_price: 0,
            short_description: item.description,
            stock_days: 0,
            supplier_price: item.price !== 0 ? this.formatNumber(item.price) : 0,
            supplier_rebate: item.margin && item.margin > 0 ? this.formatNumber(item.margin) : 0,
            value: item.price !== 0 ? this.formatNumber(item.price) * this.formatNumber(item.quantity, 1) : 0,
            vat: item.pdv && item.pdv > 0 ? this.formatNumber(item.pdv) : 0,
            vat_amount: 0,
            wholesale_price: 0,
            price_without_vat: 0,
            received_amount_measure_unit: mu,
            received_amount_purchase_price: pp,
            received_amount_quantity: qu
          }
        }

        extendedItems.push(newItem)
      })

      try {
        const payload = {
          action: {
            operation: 'set',
            entity: 'other_document',
            params: {
              supplier_id: this.supplier.id,
              document_id: uuidv4(),
              id: 'ddf7ea58-2988-4d4a-9240-7f462d3bccae',
              warehouse_id: this.warehouse.id,
              warehouse_name: this.warehouse.name,
              comment: '',
              customer_address: this.supplier?.address,
              customer_attention: '',
              customer_city: this.supplier.city,
              customer_key: '',
              customer_mail: this.supplier.email ? this.supplier.email : '',
              customer_name: this.supplier.name,
              customer_oib: this.supplier.oib,
              customer_order_number: '',
              customer_phone: this.supplier.phone ? this.supplier.phone : '',
              customer_pono: '',
              customer_tax_id: '',
              customer_zip: this.supplier.zipcode ? this.supplier.zipcode : '',
              delivery_number: this.deliveryNumber && this.deliveryNumber !== '' ? this.deliveryNumber : '',
              due_date: 0,
              fob_description: this.order.orderData.payment_method,
              fob_key: '',
              location_name: this.warehouse?.location?.name !== '' ? this.warehouse?.location?.name : '',
              order_date: 0,
              quality_check_level_id: '',
              request_date: 0,
              ship_address: this.warehouse?.address !== '' ? this.warehouse?.address : '',
              ship_city: this.warehouse?.city !== '' ? this.warehouse?.city : '',
              ship_date: 0,
              ship_doc_number: '',
              ship_name: '',
              ship_to_key: '',
              ship_zip: '',
              tran_number: '',
              vendor_id: '',
              vendor_key: '',
              company_id: state.getCurrentCompany().id,
              doc_type: 'deliverynote',
              location_id: this.warehouse?.location?.id !== '' ? this.warehouse?.location?.id : '',
              extended_items: extendedItems,
              order_id: this.order.orderData.id
            }
          }
        }

        var userID = auth.currentUser.uid

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${userID}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'warehousedocument',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .catch((err) => {
            this.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })
          })
        const docRef = df.doc(`response/${reqId}`)
        const unsubscribe = docRef.onSnapshot((doc) => {
          if (this.$refs && doc.data()) {
            const resp = doc.data()
            if (resp !== undefined && resp.header !== undefined && resp.header.code === 200) {
              this.showMsgBox({
                text: 'Primka uspješno kreirana!',
                actions: ['cancel'],
                cancelBtnText: 'OK',
                color: 'error'
              })
              this.close()
              this.$emit('deliveryNoteDone')
            } else {
              this.showMsgBox({
                text: 'An error has occurred',
                actions: ['cancel'],
                cancelBtnText: 'OK',
                color: 'error'
              })
            }
            this.loading = false
            this.close()
            unsubscribe()
          }
        })
      } catch (err) {
        this.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.loading = false
      }
    },
    filterOnlyCapsText (value, search, item) {
      return value != null &&
         search.toUpperCase() != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search.toUpperCase()) !== -1
    },
    changedQuantity (item) {
      let diff = item.quantity - item.quantityOld

      if (diff === 0) {
        diff = -item.quantity
      }

      this.order.items.forEach((it, key) => {
        if (item.name === it.name) {
          this.order.items[key].quantityOld = it.quantity
        }
      })
    },
    open (order) {
      this.order = order
      this.supplier = this.order.supplier
      this.location = this.order.orderData.location_details

      this.getWarehouse()
      this.dialogOpen = true
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    close () {
      this.dialogOpen = false
    }
  }
}
</script>
<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}
tr:hover {
  cursor: pointer;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: 20px !important;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
