var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pb-0 mb-0"},[_c('v-container',{staticClass:"pb-0 mb-0"},[_c('v-col',{staticClass:"d-flex justify-end",staticStyle:{"margin-top":"10px"},attrs:{"height":"30px"}},[_c('v-btn',{staticClass:"white--text loginButton",attrs:{"disabled":_vm.nextDisabled},on:{"click":function($event){return _vm.openConfirmDialog()}}},[_vm._v(" "+_vm._s(_vm.$t("$vuetify.order").split("|")[0])+" ")])],1),(_vm.totalWithDiscount === 0)?_c('v-row',{staticClass:"pb-0 mb-0"},[_c('v-col',{staticClass:"pb-0 mb-0"},[_c('p',{staticClass:"font-weight-bold pb-0 mb-0"},[_vm._v(_vm._s(_vm.$t("$vuetify.total")))])]),_c('v-col',{staticClass:"pb-0 mb-0"},[_c('p',{staticClass:"font-weight-bold pb-0 mb-0"},[_vm._v(" "+_vm._s(_vm._f("money")(_vm.total,1, _vm.currency))+" ")])])],1):_vm._e(),(_vm.totalWithDiscount === 0 && _vm.currency === 'EUR')?_c('v-row',{staticClass:"mt-0 pt-0 pb-0 mb-0"},[_c('v-col',{staticClass:"mt-0 pt-0 pb-0 mb-0"},[_c('p',{staticClass:"font-weight-bold mt-0 pt-0"})]),_c('v-col',{staticClass:"mt-0 pt-0 pb-0 mb-0"},[_c('p',{staticClass:"font-weight-bold mt-0 pb-0 mb-0"},[_vm._v(" "+_vm._s(_vm._f("money")(_vm.eurTotal,1, "EUR"))+" ")])])],1):_vm._e(),(_vm.totalWithDiscount === 0 && _vm.currency === 'HRK')?_c('v-row',{staticClass:"mt-0 pt-0 pb-0 mb-0"},[_c('v-col',{staticClass:"mt-0 pt-0 pb-0 mb-0"},[_c('p',{staticClass:"font-weight-bold mt-0 pt-0"})]),_c('v-col',{staticClass:"mt-0 pt-0 pb-0 mb-0"},[_c('p',{staticClass:"font-weight-bold mt-0 pb-0 mb-0"},[_vm._v(" "+_vm._s(_vm._f("money")(_vm.hrkTotal,1, "HRK"))+" ")])])],1):_vm._e(),(
          _vm.totalWithDiscount === 0 &&
          (_vm.currency === 'EUR' || _vm.currency === 'HRK')
        )?_c('v-row',{staticClass:"pt-0 mt-0 pb-0 mb-0"},[_c('v-col',{staticClass:"mt-0 pt-1 pl-3 pb-0 mb-0"},[_c('p',{staticClass:"mt-0",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s("Tečaj 1 EUR = 7,53450 HRK")+" ")])])],1):_vm._e()],1)],1),_c('div',[_c('v-col',{staticClass:"d-flex justify-end pt-0 pb-0 mb-0",attrs:{"height":"30px"}},[(_vm.items.length > 0)?_c('v-text-field',{staticClass:"pt-0 mt-0 pb-0 mb-0",attrs:{"dense":"","outlined":"","hide-details":"","label":"Pretraži košaricu"},on:{"change":function($event){return _vm.searchCart()}},model:{value:(_vm.cartFind),callback:function ($$v) {_vm.cartFind=$$v},expression:"cartFind"}}):_vm._e()],1)],1),_c('v-simple-table',{staticClass:"dynamicTable",attrs:{"height":_vm.items.length > 0 ? "57vh" : 50},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[(_vm.items.length > 0)?_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},[_vm._l((_vm.items),function(item,i){return [_c('div',{key:i,class:{ 'deletable-div ': _vm.hover === i }},[(i > 0)?_c('v-divider'):_vm._e(),_c('v-list-item',{attrs:{"dense":""}},[_c('v-avatar',{attrs:{"size":_vm.$vuetify.breakpoint.mdAndUp ? '55' : '45'}},[(item.imageUrl)?_c('v-img',{attrs:{"src":item.imageUrl,"contain":""}}):_c('v-img',{attrs:{"src":require("@/assets/no-item.jpg")}})],1),_c('v-list-item-content',{staticClass:"item-details align-start pl-3 d-flex justify-center",staticStyle:{"max-width":"60% !important"}},[_c('div',{staticClass:"font-weight-bold pl-1 text-subtitle mt-1"},[_vm._v(" "+_vm._s(item.articleName)+" ")]),(
                      item.unitPriceWithDiscount === undefined ||
                      item.unitPriceWithDiscount === 0
                    )?_c('v-list-item-subtitle',{staticClass:"pl-1"},[_vm._v(" "+_vm._s(_vm._f("money")(item.unitPrice,1))+" ")]):_vm._e(),(
                      item.unitPriceWithDiscount > 0 &&
                      item.unitPriceWithDiscount > item.unitPrice
                    )?_c('v-list-item-subtitle',{staticClass:"pl-1"},[_c('s',[_vm._v(_vm._s(_vm._f("money")(item.unitPriceWithDiscount,1)))])]):_vm._e(),(item.unitPriceWithDiscount > 0)?_c('v-list-item-subtitle',{staticClass:"pl-1"},[_vm._v(" "+_vm._s(_vm._f("money")(item.unitPriceWithDiscount,1))+" ")]):_vm._e(),_c('div',{style:(("width: " + _vm.priceInputWidth))},[_c('quantity-input',{ref:"quantityInput",refInFor:true,staticClass:"centered-input",attrs:{"dense":"","autocomplete":"off","val":item.quantity},on:{"change":function($event){return _vm.changedQuantity(item)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"red","hover":"","dense":""},on:{"click":function($event){return _vm.reduceQuantity(item)}}},[_vm._v(" mdi-minus ")])]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"color":"green","hover":"","dense":""},on:{"click":function($event){return _vm.increaseQuantity(item)}}},[_vm._v(" mdi-plus ")])]},proxy:true}],null,true),model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})],1)],1)],1),(i == _vm.items.length - 1)?_c('v-divider'):_vm._e()],1)]})],2):_c('v-container',{staticClass:"d-flex justify-center align-center"},[_c('span',{staticClass:"text-title"},[_vm._v("Dodajte artikle u narudžbu")])])],1)]},proxy:true}])}),_c('order-confirm',{ref:"orderConfirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }