<template>
  <div>
    <div class="pb-0 mb-0">
      <v-container class="pb-0 mb-0">
        <v-col
          class="d-flex justify-end"
          height="30px"
          style="margin-top: 10px"
        >
          <v-btn
            class="white--text loginButton"
            :disabled="nextDisabled"
            @click="openConfirmDialog()"
          >
            Kreiraj primku
          </v-btn>
        </v-col>
        <v-row class="pb-0 mb-0">
          <v-col class="pb-0 mb-0">
            <p class="font-weight-bold pb-0 mb-0">{{ $t("$vuetify.total") }}</p>
          </v-col>
          <v-col class="pb-0 mb-0">
            <p class="font-weight-bold pb-0 mb-0">
              {{ total | money(1, currency) }}
            </p>
          </v-col>
        </v-row>

        <!-- ISPIS KONVERZIJE U KN -->
        <v-row class="mt-0 pt-0 pb-0 mb-0">
          <v-col class="mt-0 pt-0 pb-0 mb-0">
            <p class="font-weight-bold mt-0 pt-0"></p>
          </v-col>
          <v-col class="mt-0 pt-0 pb-0 mb-0">
            <p class="font-weight-bold mt-0 pb-0 mb-0">
              {{ hrkTotal | money(1, "HRK") }}
            </p>
          </v-col>
        </v-row>
        <v-row class="pt-0 mt-0 pb-0 mb-0">
          <v-col class="mt-0 pt-1 pl-3 pb-0 mb-0">
            <p class="mt-0" style="font-size: 12px">
              {{ "Tečaj 1 EUR = 7,53450 HRK" }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div>
      <v-col class="d-flex justify-end pt-0 pb-0 mb-0" height="30px">
        <v-text-field
          dense
          class="pt-0 mt-0 pb-0 mb-0"
          outlined
          hide-details
          v-if="items.length > 0"
          label="Pretraži košaricu"
          v-model="cartFind"
          @change="searchCart()"
        >
        </v-text-field>
      </v-col>
    </div>
    <v-simple-table
      :height="items.length > 0 ? `43vh` : 50"
      class="dynamicTable"
    >
      <template v-slot:default>
        <tbody>
          <v-list v-if="items.length > 0" dense class="py-0">
            <template v-for="(item, i) in items">
              <div :key="i" :class="{ 'deletable-div ': hover === i }">
                <v-divider v-if="i > 0"></v-divider>
                <v-list-item>
                  <v-list-item-content
                    class="pa-0 ma-0 pr-0 pl-0 mr-0 ml-0 align-center d-flex justify-center"
                    style="max-width: 100% !important"
                  >
                    <v-col cols="6" class="pa-0 ma-0">
                    <div class="font-weight-bold pl-1 text-subtitle mt-1">
                      {{ item.name }}
                    </div>
                    <v-list-item-subtitle class="pl-1">
                      {{
                        item.price
                          ? $options.filters.money(item.price, 1, currency)
                          : "Cijena nije definirana"
                      }}
                    </v-list-item-subtitle>
                  </v-col>
                  <v-col cols="6" class="pa-0 ma-0">
                    <div :style="`width: ${priceInputWidth}`">
                      <quantity-input
                        dense
                        v-model="item.quantity"
                        autocomplete="off"
                        class="centered-input"
                        :val="item.quantity"
                        ref="quantityInput"
                        @change="changedQuantity(item)"
                      >
                        <template v-slot:prepend>
                          <v-icon
                            color="red"
                            hover
                            dense
                            @click="reduceQuantity(item)"
                          >
                            mdi-minus
                          </v-icon>
                        </template>
                        <template v-slot:append-outer>
                          <v-icon
                            color="green"
                            hover
                            dense
                            @click="increaseQuantity(item)"
                            class="mr-5"
                          >
                            mdi-plus
                          </v-icon>
                          <v-icon @click="removeItemFromBasket(item)"
                            >mdi-delete</v-icon
                          >
                        </template>
                      </quantity-input>
                    </div>
                  </v-col>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="i == items.length - 1"></v-divider>
              </div>
            </template>
          </v-list>
          <v-container v-else class="d-flex justify-center align-center">
            <span class="text-title">Dodajte artikle u narudžbu</span>
          </v-container>
        </tbody>
      </template>
    </v-simple-table>
    <order-confirm ref="orderConfirmDialog"></order-confirm>
  </div>
</template>
<script>
import { df } from '@/plugins/firebase'
import EventBus from '@/plugins/event-bus'
import QuantityInput from '@/components/QuantityInput'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import OrderConfirm from '@/modules/point-of-sale/components/orders/other/receive-order/calculator/OrderConfirmReceive.vue'
import { clone } from '@/plugins/utils'
export default {
  props: ['data'],
  mixins: [applicationSettings],
  components: { QuantityInput, OrderConfirm },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: function () {
    return {
      hover: -1,
      items: [],
      oldItems: [],
      orderCart: undefined,
      currencies: [],
      currency: 'EUR',
      listener: [],
      cartFind: undefined,
      realItems: [],
      supplier: undefined,
      orderData: undefined,
      multiplier: 0
    }
  },
  computed: {
    priceInputWidth () {
      return this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.smAndDown ? '100%' : '100%'
    },
    nextDisabled () {
      return this.items.length <= 0
    },
    hrkTotal () {
      const m = 100.00
      const inFloat = this.total.toFixed(2)
      const trueVal = inFloat / 100
      const tConv = trueVal.toFixed(2) * 7.53450
      const tOut = Math.round(tConv * m)
      return tOut.toFixed(2)
    },
    total () {
      var allHavePrice = true
      let tempTotal = 0
      this.items.forEach(item => {
        if (item.price && item.price > 0) {
          tempTotal += (item.price * this.formatNumber(item.quantity, 1))
        } else {
          allHavePrice = false
        }
      })

      if (allHavePrice === true) {
        return tempTotal
      } else {
        return 0
      }
    }
  },
  watch: {
    data (val) {
      if (!val || !val.items) {
        this.items = []
      }
    },
    cartFind (val) {
      if (val === '') {
        this.items = this.realItems
      }
    }
  },
  created () {
    this.getMultiplier()
  },
  mounted () {
    this.currencies = [
      'HRK', 'EUR', 'USD', 'GBP'
    ]
    EventBus.$on('receive-item', order => this.addItem(order))
    EventBus.$on('receive-all-items', order => this.addAllItems(order))
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  },
  methods: {
    formatNumber (num, multiplier = 100) {
      if (!num || num === '' || num === 0) return 0

      var bla = num.toString().trim()
      if (bla === '') return 0

      var number = bla.replace(/,/g, '.')
      number = parseFloat(number)
      return parseInt(number * multiplier)
    },
    addAllItems (order) {
      var items = clone(order.items)
      items.forEach(item => {
        if (this.items.some(it => it.id === item.id)) {
          this.items.map(it => {
            if (it.id === item.id) {
              it.quantity = parseFloat(it.quantity.toString().replace(',', '.')) + parseInt(item.quantity_in_package / this.multiplier)
              it.quantity = it.quantity.toFixed(3).replace('.', ',')
            }
            return it
          })
        } else {
          if (item.price && item.price > 0) {
            item.price /= 100
          }
          if (item.pdv && item.pdv > 0) {
            item.pdv /= 100
          }
          if (item.margin && item.margin > 0) {
            item.margin /= 100
          }
          this.items.push({ ...item, ...{ quantity: (item.quantity / 1000).toFixed(3).replace('.', ',') } })
        }
      })

      this.supplier = order.supplier
      this.orderData = order.orderData

      this.openConfirmDialog()
    },

    addItem (order) {
      var item = clone(order.item)
      item.price /= 100
      item.margin /= 100
      item.pdv /= 100

      this.supplier = order.supplier
      this.orderData = order.orderData

      if (this.items && this.items.some(it => it.id === item.id)) {
        this.items.map(it => {
          if (it.id === item.id) {
            if (item.quantity_in_package && item.quantity_in_package !== '' && item.quantity_in_package !== '0') {
              it.quantity = parseFloat(it.quantity.toString().replace(',', '.')) + parseInt(item.quantity_in_package / this.multiplier)
            } else {
              it.quantity = parseFloat(it.quantity.toString().replace(',', '.')) + 1
            }
            it.quantity = it.quantity.toFixed(3).replace('.', ',')
          }
          return it
        })
      } else {
        if (item.quantity_in_package && item.quantity_in_package !== '' && item.quantity_in_package !== '0') {
          this.items.unshift({ ...item, ...{ quantity: parseInt(item.quantity_in_package / this.multiplier).toFixed(3).replace('.', ','), quantity_in_package: item.quantity_in_package / this.multiplier, price: item.price && item.price > 0 ? item.price : 0, pdv: item.pdv && item.pdv > 0 ? item.pdv : 0, margin: item.margin && item.margin > 0 ? item.margin : 0 } })
        } else {
          this.items.unshift({ ...item, ...{ quantity: '1,000' } })
        }
        // this.items.push({ ...item, ...{ quantity: item.quantity.toFixed(3).replace('.', ',') } })
      }
    },
    openConfirmDialog () {
      var order = {
        items: this.items,
        supplier: this.supplier,
        orderData: this.orderData
      }

      if (this.$refs.orderConfirmDialog) {
        this.$refs.orderConfirmDialog.open(order)
      }
    },
    clearCartFind () {
      this.cartFind = ''
    },
    searchCart () {
      if (this.cartFind !== '') {
        this.realItems = this.items
        const tmp = []
        this.items.forEach(item => {
          if (item.name.includes(this.cartFind.toUpperCase())) {
            tmp.push(item)
          }
        })
        this.items = tmp
      }
    },
    changedQuantity (item) {
      let diff = item.quantity - item.quantityOld
      if (diff === 0) {
        diff = -item.quantity
      }

      this.items.forEach((it, key) => {
        if (item.articleID === it.articleID) {
          this.items[key].quantityOld = it.quantity
        }
      })
    },

    capitalizeFirst (name) {
      if (name !== undefined && name !== null && name.length > 2) {
        return name.charAt(0).toUpperCase() + name.slice(1)
      }
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },

    setCurrency () {
      this.$emit('currencyChanged', this.currency)
      this.items = []
    },

    reduceQuantity (item) {
      this.items = this.items.map(it => {
        if (it.id === item.id) {
          if (item.quantity_in_package && item.quantity_in_package !== '' && item.quantity_in_package !== '0') {
            it.quantity = parseFloat(it.quantity.toString().replace(',', '.')) - parseInt(item.quantity_in_package)
          } else {
            it.quantity = parseFloat(it.quantity.toString().replace(',', '.')) - 1
          }

          if (it.quantity > 0) {
            it.quantity = it.quantity.toFixed(3).replace('.', ',')
          }

          // // const decimals = it.quantity.toString().split(/[.|,]/)
          // // let decimalPlaces = 0
          // // if (decimals[1]) decimalPlaces = decimals[1].length
          // it.quantity = it.quantity.toString().replace(',', '.') - 1 >= 0 ? it.quantity.toString().replace(',', '.') - 1 : 0
          // // if (decimalPlaces) { it.quantity = it.quantity.toString().substr(0, decimals[0].length + decimals[1].length + 1) }
          // if (it.quantity > 0) {
          //   it.quantity = it.quantity.toFixed(3).replace('.', ',')
          // }
        }
        return it
      })
      this.items = this.items.filter(it => it.quantity)
    },

    increaseQuantity (item) {
      this.items = this.items.map(it => {
        if (it.id === item.id) {
          if (item.quantity_in_package && item.quantity_in_package !== '' && item.quantity_in_package !== '0') {
            it.quantity = parseFloat(it.quantity.toString().replace(',', '.')) + parseInt(item.quantity_in_package)
          } else {
            it.quantity = parseFloat(it.quantity.toString().replace(',', '.')) + 1
          }
          it.quantity = it.quantity.toFixed(3).replace('.', ',')
        }
        return it
      })
    },
    removeItemFromBasket (item) {
      this.items = this.items.filter(it => it !== item)
      this.hover = -1
    },
    allQuantitiesSet () {
      if (!this.items || !Array.isArray(this.items)) return false
      return this.items.every(item => this.isCorrectQuantity(item.quantity))
    },
    isCorrectQuantity (quantity) {
      const priceRx = /^([0-9]+)$|^(([0-9]+)[,|.]{1}([0-9]+))$/
      return quantity.toString().match(priceRx)
    }
  }
}
</script>
<style scoped>
.deletable-div {
  opacity: 0.6;
}
.deletable-div .delBtn {
  font-size: 38px !important;
}
.item-details {
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.dynamicTable {
  overflow: auto;
}

.centered-input >>> input {
  text-align: center;
}

.deletable-div .minBtn {
  font-size: 38px !important;
}

.deletable-div .disBtn {
  font-size: 38px !important;
}
</style>
