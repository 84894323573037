<template>
  <v-dialog
    v-model="dialogOpen"
    persistent
    max-width="630"
    @keydown.esc="close"
  >
    <v-overlay v-show="loading" absolute z-index="10">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-card
      class="pb-5"
      style="overflow-x: hidden"
      :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
    >
      <v-card-title class="d-flex" style="vertical-align: middle">
        <v-row class="pa-3 mb-2">
          <h3 class="text--secondary">Podaci o narudžbi</h3>
          <v-spacer></v-spacer>
          <v-icon large @click="close">mdi-close</v-icon>
        </v-row>
      </v-card-title>
      <v-container
        fluid
        class="
          d-flex
          justify-center
          align-center
          flex-wrap
          pa-0
          mt-0
          fill-height
        "
      >
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="11"
            lg="11"
            :class="['px-0']"
            v-if="order !== undefined"
          >
            <h4>
              Broj narudžbe:
              {{ order.orderId }}
            </h4>
            <v-row class="pa-0 ma-0">
              <h4 class="pt-1">Adresa:</h4>
              <v-col cols="6" class="pa-0 ma-0">
                <v-select
                  dense
                  style="font-size: 10px; font-weight: bold"
                  class="pa-0 ma-0 pl-2"
                  :items="addresses"
                  v-model="address"
                  item-text="name"
                  item-value="value"
                >
                </v-select>
              </v-col>
            </v-row>
            <h4>
              Naručio:
              {{ order.createSystemUser.name.toUpperCase() }}
            </h4>

            <v-row class="pa-0 ma-0">
              <h4 class="pt-1">Način plaćanja:</h4>
              <v-col cols="3" class="pa-0 ma-0">
                <v-select
                  dense
                  style="font-size: 10px; font-weight: bold"
                  class="pa-0 ma-0 pl-2"
                  :items="receiptTypes"
                  v-model="receiptType"
                  item-text="name"
                  item-value="value"
                >
                </v-select>
              </v-col>
            </v-row>
            <br />
            <v-data-table
              v-if="
                order !== undefined &&
                order.products !== undefined &&
                order.products.length > 0
              "
              :headers="orderHeaders"
              :items="order.products"
              :items-per-page="5"
              :search="searchTable"
              class="elevation-1 pb-5 mb-5"
              hide-default-footer
              :custom-filter="filterOnlyCapsText"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Proizvodi</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="searchTable"
                    label="Pretraži proizvode"
                    class="mt-4"
                  ></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <v-img
                      v-if="item.imageUrl"
                      :src="item.imageUrl"
                      contain
                      height="48"
                    ></v-img>
                  </td>
                  <td>{{ item.articleID }}</td>
                  <td>{{ item.articleName }}</td>
                  <td>{{ item.unitPriceWithDiscount | money(1, currency) }}</td>
                  <td>{{ item.unitQuantity }}</td>
                  <td>
                    <v-text-field
                      v-model="item.quantity"
                      outlined
                      flat
                      dense
                      @change="changedQuantity(item)"
                    ></v-text-field>
                  </td>
                </tr>
              </template>
            </v-data-table>

            <div class="text-right">
              <h4>
                Iznos narudžbe:
                {{ order.totalOrderPrice | money(1, currency) }}
              </h4>
              <br />
              <v-btn
                right
                bottom
                class="white--text loginButton"
                @click="finishOrder()"
              >
                Naruči
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import rules from '@/plugins/rules'
import { functions, df, auth } from '@/plugins/firebase'
import state from '@/state'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings'
import { clone } from '@/plugins/utils'

export default {
  components: {
  },
  mixins: [flow, applicationSettings],
  inject: [
    'showMsgBox',
    'showLoader',
    'hideLoader',
    'openPdfPrint',
    'closePdfPrint'
  ],
  data: function () {
    return {
      dialogOpen: false,
      loading: false,
      order: undefined,
      location: {},
      currency: 'EUR',
      locations: [],
      associates: [],
      lastVisible: {},
      selected: {},
      multiplier: 0,
      searchTable: undefined,
      show: false,
      addresses: [
      ],
      address: undefined,
      receiptTypes: [],
      receiptType: undefined,
      rules: {
        req: rules.req(),
        oib: rules.oib()
      },
      orderHeaders: [
        {
          text: '',
          value: 'imageUrl'
        },
        {
          text: 'Id artikla',
          value: 'articleName'
        },
        {
          text: 'Naziv artikla',
          value: 'articleName'
        },
        {
          text: 'Cijena artikla',
          value: 'unitDiscountPrice'
        },
        {
          text: 'Količina u paketu',
          value: 'unitQuantity'
        },
        {
          text: 'U narudžbi',
          value: 'quantity'
        }
      ]
    }
  },
  computed: {
    now () {
      return parseInt((`${new Date().getTime()}` / 1000).toFixed(0))
    },
    locs: {
      get: function () {
        return this.locations
      },
      set: function (nv) {
        this.locations = [
          { name: this.$t('$vuetify.all'), id: '', location_id: '' }
        ].concat(nv)
      }
    }
  },
  watch: {
    location: {
      deep: true,
      handler (nv, ov) {
        if (nv !== ov) {
          // paginate more columns
          this.lastVisible = {}
        }
      }
    }
  },
  mounted () {
    this.getAssociates()
    this.getMultiplier()
    this.locations = [
      { name: this.$t('$vuetify.all'), id: '', location_id: '' }
    ]
    this.location = this.locations[0]

    const docRef = df.doc(
      `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
    )
    const listener = docRef.onSnapshot(doc => {
      if (
        doc &&
        doc.data() &&
        doc.data().locations &&
        Object.keys(doc.data().locations).length > 0
      ) {
        this.locs = Object.keys(doc.data().locations).map(key => {
          return doc.data().locations[key]
        })
      }
    })
    this.listeners.push(listener)
  },
  methods: {
    async finishOrder () {
      this.loading = true
      const params = {
        company_id: state.getCurrentCompany().id,
        location_id: '',
        receipt_type: '',
        delivery_note: '',
        address_id: '',
        delivery_date: ''
      }

      var ordersApi = functions.httpsCallable('rotoorders')
      await ordersApi({
        action: {
          operation: 'set',
          entity: 'order',
          params: params
        },
        attributes: {
        }
      })
        .then((result) => {
          if (result.data !== undefined && result.data.data !== undefined) {

          }
          this.loading = false
        })
      this.loading = false
    },
    filterOnlyCapsText (value, search, item) {
      return value != null &&
         search.toUpperCase() != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search.toUpperCase()) !== -1
    },
    async callHttps (params) {
      this.loading = true
      params.company_id = state.getCurrentCompany().id

      var ordersApi = functions.httpsCallable('rotoorders')
      await ordersApi({
        action: {
          operation: 'update',
          entity: 'order',
          params: params
        },
        attributes: {
        }
      })
        .then((result) => {
          if (result.data !== undefined && result.data.data !== undefined) {
            this.orderCart = result.data.data
          }

          if (this.orderCart !== undefined) {
            if (this.orderCart.products !== undefined && this.orderCart.products.length > 0) {
              this.items = this.orderCart.products
              this.items.forEach(item => {
                item.quantityOld = item.quantity
              })

              this.orderCart.products = this.items
              this.order = this.orderCart
            }
          }
          this.loading = false
        })
      this.loading = false
    },
    changedQuantity (item) {
      let diff = item.quantity - item.quantityOld

      if (diff === 0) {
        diff = -item.quantity
      }

      this.order.products.forEach((it, key) => {
        if (item.articleID === it.articleID) {
          this.order.products[key].quantityOld = it.quantity
        }
      })

      var params = {
        supplier: 'roto',
        item_id: item.articleID,
        quantity: diff
      }

      this.callHttps(params)
    },
    open (order) {
      this.order = order

      this.addresses = [
        {
          name: this.order.address.streetAndNumber.toUpperCase() +
                ', ' +
                this.order.address.postalCode +
                ' ' +
                this.order.address.city.toUpperCase(),
          value: this.order.address.addressId
        }
      ]

      this.receiptTypes = [
        {
          name: this.order.receiptType,
          value: this.order.receiptType
        }
      ]

      this.address = this.addresses[0]
      this.receiptType = this.receiptTypes[0]
      this.dialogOpen = true
    },
    applyFilter () {
      this.lastVisible = {}
      this.orders = []
      this.$refs.InfiniteLoading.stateChanger.reset()
    },

    navigateToFlow () {
      this.$router.push({
        name: 'Home',
        params: {
        }
      })
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    getAssociates () {
      df.doc(`company_associates/${state.getCurrentCompany().id}`)
        .get()
        .then((resp) => {
          if (resp && resp.data()) {
            this.defaultAssociates = Object.keys(resp.data().associates)
              .map((key) => {
                return resp.data().associates[key]
              })
              .filter((associate) => associate.status === 'OK' && associate.associate_type.BUYER)
            this.associates = clone(this.defaultAssociates)
          }
        })
    },
    close () {
      this.dialogOpen = false
    }
  }
}
</script>
<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}
tr:hover {
  cursor: pointer;
}
</style>

<style>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
