<template>
  <v-container fluid class="fill-height pa-0">
    <v-container
      fluid
      class="d-flex justify-center align-center flex-wrap pa-0 fill-height"
    >
      <v-row class="justify-center fill-height">
        <v-col md="8" lg="9" class="pt-0 mt-0">
          <div>
            <v-row class="justify-center ma-0 ml-1">
              <v-form :key="i" v-for="(category, i) in categories">
                <v-btn-toggle
                  v-model="category.active"
                  @change="getProducts()"
                  borderless
                  class="pa-0 ma-0"
                >
                  <v-btn
                    style="background-color: transparent; height: 110px"
                    depressed
                    class="pa-0 ma-0"
                    elevation="0"
                    v-bind:color="
                      category.active !== undefined ? '#d4d4d4' : ''
                    "
                  >
                    <div>
                      <v-avatar :size="$vuetify.breakpoint.smAndDown ? 55 : 70">
                        <v-img
                          v-if="category.picture"
                          :src="category.picture"
                          contain
                          height="100"
                        >
                        </v-img>
                        <v-icon
                          v-else-if="
                            category.picture === '' &&
                            category.name === 'Akcija'
                          "
                          :size="$vuetify.breakpoint.smAndDown ? 45 : 60"
                        >
                          mdi-brightness-percent
                        </v-icon>
                        <v-icon
                          v-else-if="
                            category.picture === '' &&
                            category.name !== 'Akcija'
                          "
                          :size="$vuetify.breakpoint.smAndDown ? 45 : 60"
                        >
                          mdi-image
                        </v-icon>
                      </v-avatar>
                      <p style="font-size: 11px">{{ category.name }}</p>
                    </div>
                  </v-btn>
                </v-btn-toggle>
              </v-form>
            </v-row>
          </div>
          <v-container class="d-flex justify-center flex-wrap pa-0">
            <v-container>
              <v-row v-if="listView" class="d-flex justify-center ma-0">
                <v-col cols="12" sm="11" lg="10">
                  <v-card
                    text
                    v-for="(item, i) in categoryItems"
                    :key="i"
                    :disabled="!isSeller"
                    @click="itemSelected(item)"
                    @keypress.enter="itemSelected(item)"
                    :hover="!transactionStart"
                    :ref="`item${i}`"
                  >
                    <v-row :class="['ma-0', 'row']">
                      <v-col
                        v-if="density !== 'greater'"
                        cols="4"
                        sm="3"
                        md="2"
                        lg="2"
                        xl="2"
                        :class="[densityPadding]"
                      >
                        <v-img
                          v-if="item.imageUrl"
                          :src="item.imageUrl"
                          contain
                          :height="density === 'normal' ? '48' : '68'"
                        ></v-img>
                        <v-img
                          v-else
                          contain
                          src="@/assets/no-item.jpg"
                          :height="density === 'normal' ? '48' : '68'"
                        ></v-img>
                      </v-col>
                      <v-col
                        cols="8"
                        sm="9"
                        md="5"
                        lg="4"
                        :class="[densityPadding]"
                      >
                        <div class="caption grey--text">Naziv artikla</div>
                        <div
                          class="one-liner-text smaller-font"
                          @mouseover="
                            hoverOver(`hover-${item.articleID}`, item)
                          "
                          @mouseleave="item[`hover-${item.articleID}`] = false"
                          :id="`hover-${item.articleID}`"
                          :ref="`hover-${item.articleID}`"
                        >
                          {{ item.articleName }}
                        </div>
                        <v-tooltip
                          v-model="item[`hover-${item.articleID}`]"
                          bottom
                        >
                          <!--Fake activator to avoid an attach property which is not working properly -->
                          <template v-slot:activator="{ on }">
                            <div v-on="on"></div>
                          </template>
                          <div class="smaller-font" style="max-width: 300px">
                            {{ item.articleName }}
                          </div>
                        </v-tooltip>
                      </v-col>
                      <v-col
                        cols="4"
                        sm="7"
                        md="2"
                        lg="2"
                        :class="[densityPadding]"
                      >
                        <div class="caption grey--text">Cijena artikla</div>
                        <div>
                          <div class="smaller-font">
                            {{ item.unitPrice | money(1, currency) }}
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        class="hidden-md-and-down"
                        cols="4"
                        md="2"
                        lg="2"
                        :class="[densityPadding]"
                      >
                        <div
                          class="caption grey--text text-sm-center text-md-left"
                        >
                          Šifra artikla
                        </div>
                        <div class="text-sm-center text-md-left smaller-font">
                          {{ item.ean }}
                        </div>
                      </v-col>
                      <v-col
                        sm="5"
                        md="3"
                        lg="2"
                        style="min-width: 100px; max-width: 100%"
                        :class="[densityPadding, 'flex-grow-1']"
                      >
                        <div class="caption grey--text t">
                          Količina u paketu
                        </div>
                        <div class="smaller-font">
                          {{ item.unitQuantity }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else class="d-flex justify-center fill-height">
                <v-card
                  class="align-center ma-5"
                  v-for="(item, i) in categoryItems"
                  :key="i"
                  @click="itemSelected(item)"
                  @keypress.enter="itemSelected(item)"
                  :hover="!transactionStart"
                  :disabled="!isSeller"
                  height="300"
                  width="250"
                  :ref="`item${i}`"
                >
                  <v-img
                    height="210"
                    width="250"
                    v-if="item.imageUrl"
                    :src="item.imageUrl"
                  >
                  </v-img>
                  <v-img
                    height="210"
                    width="250"
                    v-else
                    src="@/assets/no-item.jpg"
                  >
                  </v-img>
                  <v-card-actions class="d-flex flex-column">
                    <h2 class="selling-point-title-text one-liner-text">
                      {{ $options.filters.capitalize(item.articleName) }}
                    </h2>
                    <!-- <h2 class="grey--text selling-point-text one-liner-text">{{$t('$vuetify.price')}}: {{item.prices[currency].price | money(100, currency)}}</h2> -->
                    <h2 class="grey--text selling-point-text one-liner-text">
                      {{ item.unitPrice | money(1, currency) }}
                    </h2>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-container>
          </v-container>
          <infinite-loading
            ref="InfiniteLoading"
            @infinite="infiniteHandler"
            spinner="waveDots"
          >
            <div slot="no-more" class="text--secondary font-italic"></div>
            <div slot="no-results" class="text--secondary font-italic"></div>
          </infinite-loading>
        </v-col>
        <v-col md="4" lg="3" class="mt-5">
          <order-calculator
            ref="orderCalculator"
            @transactionStarted="transactionStarted"
            @transactionEnded="transactionEnded"
            @currencyChanged="setCurency"
          ></order-calculator>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import { auth, df, functions } from '@/plugins/firebase'
import state from '@/state'
import OrderCalculator from '@/modules/point-of-sale/components/orders/roto/create-order/OrderCalculatorRoto'
import InfiniteLoading from 'vue-infinite-loading'
import applicationSettings from '@/mixins/applicationSettings'
import EventBus from '@/plugins/event-bus'
import rules from '@/plugins/rules'

import barcodeChecker from '@/mixins/barcodeChecker' // don't move.. detaches firebase listeners
export default {
  name: 'CreateOrderRoto',
  components: { OrderCalculator, InfiniteLoading },
  mixins: [applicationSettings, barcodeChecker],
  inject: ['showMsgBox', 'confirm', 'confirmClose', 'showLoader', 'hideLoader', 'showMsgBoxHtml'],
  data: () => ({
    writeInProductDialog: false,
    transactionStart: false,
    suppliers: [
    ],
    loading: false,
    category: null,
    cashReg: undefined,
    selectedCategory: 'ACTION',
    categories: [
      { value: 'ACTION', name: 'Akcija', active: 0, picture: '' },
      { value: 'A', name: 'Pivo', picture: require('@/assets/pivo.jpg') },
      { value: 'B', name: 'Vino', picture: require('@/assets/vino.jpg') },
      { value: 'C', name: 'Jaka alkoholna pića', picture: require('@/assets/viski.jpeg') },
      { value: 'D', name: 'Voda', picture: require('@/assets/voda.jpeg') },
      { value: 'E', name: 'Sokovi', picture: require('@/assets/sokovi.jpg') },
      { value: 'P', name: 'Kava, instanti i kavovina', picture: require('@/assets/kava.jpg') },
      { value: 'O', name: 'Svi ostali artikli', picture: require('@/assets/ostali.jpg') }
    ],
    categoryItems: [],
    _categoryItems: [],
    selectedItem: undefined,
    currency: 'EUR',
    multiplier: 0,
    lastVisible: '',
    listeners: [],
    cashRegListeners: [],
    rotoSupplier: false,
    moreCategories: [],
    user: {},
    rules: {
      req: rules.req(),
      eq: rules.eq,
      oib: rules.oib(),
      arrReq: rules.arrReq()
    },
    expanded: false
  }),
  props: {
    supplier: {
      type: Object,
      default: () => {}
    },
    location: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    listView () {
      return state.isListView()
    },
    search () {
      return state.search
    },
    isSeller () {
      return state.getUser()?.roles?.PRODAVAČ
    },
    isAdmin () {
      return state.getUser()?.roles?.SUPERADMIN || state.getUser()?.roles?.ADMIN
    }
  },
  watch: {
    category (nv, ov) {
      if (nv !== ov) {
        this.selectCategory(this.categories[nv])
        this.tabChange()
      }
    },
    search () {
      this.searchItems()
    }
  },
  mounted () {
    EventBus.$emit('roto-location', this.location)
    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }

    var query = df.collection('suppliers').where('company_ids', 'array-contains', state.getCurrentCompany().id)

    const listener = query
      .onSnapshot((doc) => {
        doc.docs.forEach((rec) => {
          this.suppliers.push(rec.data())
        })
      })

    this.listeners.push(listener)
    EventBus.$on('set-mini', () => {
      this.expanded = !this.expanded
    })

    this.cashReg = state.getCashRegister()
    this.getMultiplier()
    if (!this.isSeller && !this.isAdmin) {
      this.showMsgBoxHtml({ text: this.$t('$vuetify.errors.isNotSeller'), actions: ['cancel'], cancelBtnText: this.$t('$vuetify.close'), color: 'error' })
    }
    if (!this.isSeller && this.isAdmin) {
      df.doc(`users/${state.getUser()?.id}`)
        .get()
        .then((user) => {
          if (user && user.exists) {
            state.setUser(user.data())
          }

          if (!user.roles?.PRODAVAČ) {
            this.confirm({
              title: this.$t('$vuetify.addSellerRole'),
              message: this.$t('$vuetify.errors.notSellerButSuperuser'),
              options: {
                toolbar: true,
                width: 410,
                confirmText: this.$t('$vuetify.addRole'),
                cancelText: this.$t('$vuetify.close')
              }
            }).then(resp => {
              if (resp) {
                this.confirmClose()
                this.$router.push({
                  name: 'pointOfSale.users',
                  params: { companyId: state.getCurrentCompany().id, locationId: state.getPointOfSale().id }
                })
                setTimeout(() => {
                  EventBus.$emit('add-role', state.getUser())
                }, 150)
              }
            })
          }
        })
    }
    try {
      this.$refs.item0[0].$el.focus()
    } catch {
      setTimeout(() => {
        if (this.$refs.item0) {
          this.$refs.item0[0].$el.focus()
        }
      }, 500)
    }
    this.loading = true

    document.onkeydown = this.handleKeyEvents
  },
  beforeDestroy () {
    this.categoryItems = []
    this.categories = []
    this.detachListeners()
  },
  methods: {
    getProducts () {
      if (this.$refs.InfiniteLoading) {
        this.categoryItems = []

        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },
    supplierSelected () {
      if (this.supplier !== undefined && this.supplier !== '' && this.supplier.integration_type === 'roto') {
        this.selectedCategory = 'ACTION'
        EventBus.$emit('supplier-selected', this.supplier)
      }
    },
    getCategories (supplierId) {
      this.listeners.push(df.doc(`suppliers/${supplierId}/categories/${supplierId}`).onSnapshot(doc => {
        const _categoreis = doc?.data()?.categories || []
        if (_categoreis.length === 0) return
        Object.keys(_categoreis).forEach(async (key, iterator) => {
          df.collection(`suppliers/${supplierId}/items`).where('category', '==', `${_categoreis[key].name}`)
            .limit(1)
            .get()
            .then((documentSnapshots) => {
              if (documentSnapshots.docs && documentSnapshots.docs.length > 0 && !documentSnapshots.docs.empty) {
                const _category = { ..._categoreis[key], [`hover-${_categoreis[key].id}`]: false }
                if (iterator < 6) this.categories.push(_category)
                if (iterator >= 6) this.moreCategories.push(_category)
              }
            })
        })
      })
      )
    },
    showWriteInProductDialog () {
      this.writeInProductDialog = true
    },

    onDialogClose () {
      this.writeInProductDialog = false
    },

    async getUser () {
      const user = await df.doc(`users/${auth.currentUser.uid}`).get()
      return user
    },
    hoverOver (ref, item) {
      const container = this.$refs[ref]
      if (!container || !container[0]) return
      if (container[0].associateHeight < container[0].scrollHeight) {
        item[ref] = true
      }
    },
    changeView () {
      state.setListView(!state.isListView())
    },
    tabChange () {
      this.lastVisible = ''
      this.categoryItems = []
      this.detachListeners()
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
      setTimeout(() => {
        if (this.$refs.item0 && this.$refs.item0[0]) {
          this.$refs.item0[0].$el.focus()
        }
      }, 500)
    },

    selectCategory (category) {
      this.selectedCategory = category
    },
    itemSelected (item) {
      if (!this.transactionStart) {
        EventBus.$emit('add-order-item', item)
      }
    },

    transactionStarted () {
      this.transactionStart = true
    },

    transactionEnded () {
      this.transactionStart = false
    },

    setCurency (currency) {
      this.currency = currency
      this.lastVisible = ''
      this.categoryItems = []
      this.detachListeners()

      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
      // this.filterItemsByCurrency()
    },
    filterItemsByCurrency () {
      this.categoryItems = this._categoryItems.map(it => {
        const itemPrice = it.prices.find(price => price.currency === this.currency)
        if (itemPrice) {
          return {
            ...it, ...{ price: itemPrice.price, currency: itemPrice.currency }
          }
        } else {
          return null
        }
      })
      this.categoryItems = this.categoryItems.filter(it => it)
    },

    handleKeyEvents (e) {
      e = e || window.event

      switch (e.keyCode) {
      }
    },

    infiniteHandler ($state) {
      if (!this.supplier) {
        $state.loaded()
        return
      }

      const where = this.search ? this.search.toLowerCase() : ''

      const params = {
        company_id: state.getCurrentCompany().id,
        location_id: this.location.id,
        supplier: 'roto',
        categories: []
      }

      this.categories.forEach(category => {
        if (category.active !== undefined) {
          if (category.value === 'ACTION') {
            params.promo = true
          } else {
            params.categories.push(category.value)
          }
        }
      })

      if (params.categories.length === 0) {
        delete params.categories
      }

      if (where !== '') {
        params.name = where
      }

      this.categoryItems = []

      var ordersApi = functions.httpsCallable('rotoorders')
      ordersApi({
        action: {
          operation: 'get',
          entity: 'products',
          params: params
        },
        attributes: {
        }
      })
        .then((result) => {
          if (result.data !== undefined && result.data.data !== undefined) {
            this.categoryItems = result.data.data
            $state.complete()
          } else {
            $state.complete()
          }
        })
    },
    searchItems () {
      this.lastVisible = ''
      this.categoryItems = []

      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
      setTimeout(() => {
        if (this.$refs.item0 && this.$refs.item0[0]) {
          this.$refs.item0[0].$el.focus()
        }
      }, 500)
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    }
  }
}
</script>
<style scoped>
.categories {
  position: sticky;
  position: -webkit-sticky; /*  for Safari */
  top: 64px;
  padding-bottom: 20px;
  z-index: 3;
  width: 100%;
}

.categories >>> .v-tabs-bar__content {
  justify-content: center;
  margin-top: 40px;
}

.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0.1px;
  z-index: 4;
  width: 100%;
}
.status-ok {
  border-left: 4px solid var(--v-success-base);
}

.status-failed {
  border-left: 4px solid var(--v-error-base);
}
.theme--light.v-tabs >>> .v-tabs-bar {
  background-color: rgb(256, 256, 256, 1) !important;
}

.theme--dark.v-tabs >>> .v-tabs-bar {
  background-color: rgb(18, 18, 18, 1) !important;
}

.ai-app-menu {
  /* border: none;
  width: 100%;
  height: 100%; */
  height: 300px;
  width: 340px;
  overflow: auto;
  overflow-x: hidden;
}

.more-category {
  cursor: pointer;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.smaller-font {
  font-size: 12px;
}
</style>
