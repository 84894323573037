<template>
  <v-container fluid class="fill-height pa-0">
    <v-container
      fluid
      class="d-flex justify-center align-center flex-wrap pa-0 fill-height"
    >
      <v-row class="justify-center fill-height">
        <v-col md="8" lg="9" class="pa-0 ma-0 pt-5">
          <div class="categories pa-0 ma-0">
            <v-row class="justify-center ma-0 ml-1">
              <v-col cols="2">
                <v-select
                  dense
                  class="flex-grow-0 flex-shrink-2 mt-0 mb-3"
                  outlined
                  label="Prodajno mjesto"
                  v-model="location"
                  :items="locations"
                  item-text="name"
                  return-object
                  :rules="[rules.req]"
                  @change="locationSelected()"
                  hide-details
                >
                </v-select>
              </v-col>
              <v-col cols="2">
                <v-select
                  dense
                  class="flex-grow-0 flex-shrink-0 mt-0 mb-3"
                  outlined
                  label="Dobavljač"
                  v-model="supplier"
                  :items="suppliers"
                  item-text="name"
                  return-object
                  :rules="[rules.req]"
                  @change="supplierSelected()"
                  hide-details
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  class="pa-0 ma-0"
                  append-icon="mdi-magnify"
                  clearable
                  dense
                  outlined
                  v-model="innerSearch"
                  label="Pretraži artikle"
                  hide-details
                  @keydown.enter="innerSearchItems()"
                  @click:clear="innerSearchItems({ reset: true })"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col md="4" lg="3" class="mt-5"> </v-col>
        <create-order-roto
          v-if="supplier && supplier.integration_type === 'roto'"
          :supplier="supplier"
          :location="location"
        ></create-order-roto>
        <create-order-other
          :supplier="supplier"
          :location="location"
          v-if="supplier && supplier.integration_type !== 'roto'"
        ></create-order-other>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import { auth, df } from '@/plugins/firebase'
import state from '@/state'
import CreateOrderRoto from '@/modules/point-of-sale/components/orders/roto/create-order/CreateOrderRoto'
import CreateOrderOther from '@/modules/point-of-sale/components/orders/other/create-order/CreateOrderOther'
import applicationSettings from '@/mixins/applicationSettings'
import EventBus from '@/plugins/event-bus'
import rules from '@/plugins/rules'

import barcodeChecker from '@/mixins/barcodeChecker' // don't move.. detaches firebase listeners
export default {
  name: 'CreateOrder',
  components: { CreateOrderRoto, CreateOrderOther },
  mixins: [applicationSettings, barcodeChecker],
  inject: ['showMsgBox', 'confirm', 'confirmClose', 'showLoader', 'hideLoader', 'showMsgBoxHtml'],
  data: () => ({
    suppliers: [
    ],
    locations: [],
    location: undefined,
    loading: false,
    supplier: undefined,
    category: null,
    multiplier: 0,
    innerSearch: undefined,
    user: {},
    rules: {
      req: rules.req(),
      eq: rules.eq,
      oib: rules.oib(),
      arrReq: rules.arrReq()
    },
    expanded: false
  }),
  computed: {
    listView () {
      return state.isListView()
    },
    cashRegisterOptions () {
      return this.getRowTabsModel()
    },
    isSeller () {
      return state.getUser()?.roles?.PRODAVAČ
    },
    isAdmin () {
      return state.getUser()?.roles?.SUPERADMIN || state.getUser()?.roles?.ADMIN
    }
  },
  mounted () {
    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }

    const docRef = df.doc(
      `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
    )
    const listener = docRef.onSnapshot(doc => {
      if (
        doc &&
        doc.data() &&
        doc.data().locations &&
        Object.keys(doc.data().locations).length > 0
      ) {
        this.locations = Object.keys(doc.data().locations).map(key => {
          return doc.data().locations[key]
        })

        this.locations = this.locations.sort((a, b) => a.name.localeCompare(b.name))
      }
    })
    this.listeners.push(listener)

    this.listeners.push(listener)
    EventBus.$on('set-mini', () => {
      this.expanded = !this.expanded
    })

    this.cashReg = state.getCashRegister()
    this.getMultiplier()
    if (!this.isSeller && !this.isAdmin) {
      this.showMsgBoxHtml({ text: this.$t('$vuetify.errors.isNotSeller'), actions: ['cancel'], cancelBtnText: this.$t('$vuetify.close'), color: 'error' })
    }
    if (!this.isSeller && this.isAdmin) {
      df.doc(`users/${state.getUser()?.id}`)
        .get()
        .then((user) => {
          if (user && user.exists) {
            state.setUser(user.data())
          }

          if (!user.roles?.PRODAVAČ) {
            this.confirm({
              title: this.$t('$vuetify.addSellerRole'),
              message: this.$t('$vuetify.errors.notSellerButSuperuser'),
              options: {
                toolbar: true,
                width: 410,
                confirmText: this.$t('$vuetify.addRole'),
                cancelText: this.$t('$vuetify.close')
              }
            }).then(resp => {
              if (resp) {
                this.confirmClose()
                this.$router.push({
                  name: 'pointOfSale.users',
                  params: { companyId: state.getCurrentCompany().id, locationId: state.getPointOfSale().id }
                })
                setTimeout(() => {
                  EventBus.$emit('add-role', state.getUser())
                }, 150)
              }
            })
          }
        })
    }
    try {
      this.$refs.item0[0].$el.focus()
    } catch {
      setTimeout(() => {
        if (this.$refs.item0) {
          this.$refs.item0[0].$el.focus()
        }
      }, 500)
    }
    this.loading = true

    document.onkeydown = this.handleKeyEvents
  },
  beforeDestroy () {
    this.detachListeners()
  },
  methods: {
    innerSearchItems (reset = false) {
      if (reset) {
        state.search = ''
      } else {
        state.search = this.innerSearch
      }
    },
    locationSelected () {
      EventBus.$emit('resetOrder', true)

      var query = df.collection('suppliers').where('company_locations', 'array-contains', state.getCurrentCompany().id + '.' + this.location.id).orderBy('name', 'asc')

      query
        .onSnapshot((doc) => {
          this.suppliers = []
          doc.docs.forEach((rec) => {
            this.suppliers.push(rec.data())
          })
        })

      this.suppliers = this.suppliers.sort((a, b) => a.name.localeCompare(b.name))

      this.supplier = undefined
    },
    supplierSelected () {
      if (this.supplier !== undefined && this.supplier !== '' && this.supplier.integration_type === 'roto') {
        this.rotoSupplier = true
        EventBus.$emit('supplier-selected', this.supplier)
      }

      EventBus.$emit('resetOrder', true)

      if (this.supplier.integration_type !== 'roto') {
        this.selectedCategory = undefined

        this.categories = []
      }
    },

    async getUser () {
      const user = await df.doc(`users/${auth.currentUser.uid}`).get()
      return user
    },

    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = this.config.item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    }
  }
}
</script>
<style scoped>
.categories {
  position: sticky;
  position: -webkit-sticky; /*  for Safari */
  top: 64px;
  padding-bottom: 20px;
  z-index: 3;
  width: 100%;
}

.categories >>> .v-tabs-bar__content {
  justify-content: center;
  margin-top: 40px;
}

.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0.1px;
  z-index: 4;
  width: 100%;
}
.status-ok {
  border-left: 4px solid var(--v-success-base);
}

.status-failed {
  border-left: 4px solid var(--v-error-base);
}
.theme--light.v-tabs >>> .v-tabs-bar {
  background-color: rgb(256, 256, 256, 1) !important;
}

.theme--dark.v-tabs >>> .v-tabs-bar {
  background-color: rgb(18, 18, 18, 1) !important;
}

.ai-app-menu {
  /* border: none;
  width: 100%;
  height: 100%; */
  height: 300px;
  width: 340px;
  overflow: auto;
  overflow-x: hidden;
}

.more-category {
  cursor: pointer;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.smaller-font {
  font-size: 12px;
}
</style>
