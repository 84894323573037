<template>
  <div>
    <v-container>
      <v-row
        style="margin-top: 2px; margin-bottom: -2rem"
        class="d-flex justify-center"
      >
        <v-col cols="3" sm="3" md="2" xl="2">
          <v-select
            :label="$t('$vuetify.companyTabs.pointsOfSale')"
            v-model="location"
            :items="locations"
            dense
            outlined
            return-object
            item-text="name"
            @change="getSuppliers()"
          >
          </v-select>
        </v-col>
        <v-col cols="3" sm="3" md="2" xl="1">
          <v-select
            dense
            outlined
            label="Dobavljač"
            v-model="supplier"
            :items="suppliers"
            return-object
            item-text="name"
            item-value="value"
            @change="resetOrders()"
          >
          </v-select>
        </v-col>
        <v-col md="4" lg="3" class="mt-5"> </v-col>
      </v-row>
      <v-row
        style="margin-top: 2px; margin-bottom: -2rem"
        class="d-flex justify-center"
      >
        <show-order-other
          v-if="!supplier || (supplier && supplier.integration_type === 'email')"
          :supplier="supplier"
          :location="location"
        >
        </show-order-other>
        <show-order-roto
          v-if="supplier && supplier.integration_type === 'roto'"
          :supplier="supplier"
        >
        </show-order-roto>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import rules from '@/plugins/rules'
import { df, auth } from '@/plugins/firebase'
import state from '@/state'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings'
import ShowOrderRoto from '@/modules/point-of-sale/components/orders/roto/receive-order/ShowOrdersRoto'
import ShowOrderOther from '@/modules/point-of-sale/components/orders/other/receive-order/ShowOrdersOther'
import EventBus from '@/plugins/event-bus'

export default {
  components: {
    ShowOrderRoto,
    ShowOrderOther
  },
  mixins: [flow, applicationSettings],
  inject: [
    'showMsgBox',
    'showLoader',
    'hideLoader',
    'openPdfPrint',
    'closePdfPrint'
  ],
  data: function () {
    return {
      admin: false,
      loading: false,
      orders: [],
      location: {},
      locations: [],
      suppliers: [],
      statusFilter: {},
      lastVisible: {},
      listeners: [],
      selected: {},
      supplier: undefined,
      multiplier: 0,
      show: false,
      rules: {
        req: rules.req(),
        oib: rules.oib()
      },
      currency: 'EUR'
    }
  },
  computed: {
    now () {
      return parseInt((`${new Date().getTime()}` / 1000).toFixed(0))
    },
    filters () {
      return [
        { name: 'Dostavljeno', value: 5 },
        { name: 'U dostavi', value: 4 },
        { name: 'U pripremi', value: 3 },
        { name: 'Zaprimljeno', value: 2 }
      ]
    }
  },
  watch: {
    statusFilter: {
      deep: true,
      handler (nv, ov) {
        if (nv !== ov) {
          this.lastVisible = {}
          this.orders = []
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    }
  },
  async mounted () {
    EventBus.$on('set-mini', () => {
      this.expanded = !this.expanded
    })

    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }
    this.admin = state.getUser()?.roles?.SUPERADMIN || state.getUser()?.roles?.ADMIN
    this.admin = !this.admin

    this.getMultiplier()

    const docRef = df.doc(
      `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
    )
    const listener = docRef.onSnapshot(doc => {
      if (
        doc &&
        doc.data() &&
        doc.data().locations &&
        Object.keys(doc.data().locations).length > 0
      ) {
        this.locations = Object.keys(doc.data().locations).map(key => {
          return doc.data().locations[key]
        })

        this.locations = this.locations.sort((a, b) => a.name.localeCompare(b.name))
      }
    })

    this.listeners.push(listener)
  },
  methods: {
    resetOrders () {
      EventBus.$emit('resetCreatedOrders', true)
    },
    getSuppliers () {
      this.resetOrders()

      this.suppliers = []
      this.supplier = undefined
      var query = df.collection('suppliers').where('company_locations', 'array-contains', state.getCurrentCompany().id + '.' + this.location.id)

      query
        .onSnapshot((doc) => {
          doc.docs.forEach((rec) => {
            this.suppliers.push(rec.data())
            this.suppliers = this.suppliers.sort((a, b) => a.name.localeCompare(b.name))
          })
        })
    },

    applyFilter () {
      this.lastVisible = {}
      this.orders = []
      this.$refs.InfiniteLoading.stateChanger.reset()
    },

    navigateToFlow () {
      this.$router.push({
        name: 'Home',
        params: {
        }
      })
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    }
  }
}
</script>
<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}
tr:hover {
  cursor: pointer;
}
</style>

<style>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
