<template>
  <v-dialog
    v-model="dialogOpen"
    transition="dialog-bottom-transition"
    fullscreen
    hide-overlay
    @keydown.esc="close"
  >
    <v-overlay v-show="loading" absolute z-index="10">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-card
      class="pb-5"
      style="overflow-x: hidden"
      :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
    >
      <v-card-title class="d-flex" style="vertical-align: middle">
        <v-row class="pa-3 mb-2">
          <h3 class="text--secondary">Podaci o narudžbi</h3>
          <v-spacer></v-spacer>
          <v-icon large @click="close">mdi-close</v-icon>
        </v-row>
      </v-card-title>
      <v-container
        fluid
        class="
          d-flex
          justify-center
          align-center
          flex-wrap
          pa-0
          mt-0
          fill-height
        "
      >
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="11"
            lg="11"
            :class="['px-0']"
            v-if="order !== undefined"
          >
            <v-row class="pa-0 ma-0">
              <h4 class="pt-1">Adresa:</h4>
              <v-col cols="1" class="pa-0 ma-0">
                <v-select
                  dense
                  style="font-size: 14px; font-weight: bold"
                  class="pa-0 ma-0 pl-1"
                  :items="locations"
                  v-model="location"
                  item-text="name"
                  item-value="id"
                  :rules="[rules.req]"
                >
                </v-select>
              </v-col>
              <h4 class="pt-1 pl-2">Način plaćanja:</h4>
              <v-col cols="1" class="pa-0 ma-0">
                <v-select
                  dense
                  style="font-size: 14px; font-weight: bold"
                  class="pa-0 ma-0 pl-2"
                  :items="paymentTypes"
                  v-model="paymentType"
                  item-text="text"
                  item-value="value"
                  :rules="[rules.req]"
                >
                </v-select>
              </v-col>
              <v-col class="pa-0 ma-0 pl-2 pr-2" cols="2">
                <v-menu v-model="deliveryDateMenu" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDeliveryDate"
                      label="Datum dostave"
                      dense
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="deliveryDate"
                    no-title
                    scrollable
                    @input="deliveryDateMenu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="pa-0 ma-0" cols="2">
                <v-menu
                  offset-y
                  v-model="deliveryTimeMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formatDeliveryTime"
                      label="Vrijeme dostave"
                      dense
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="deliveryTime"
                    full-width
                    format="24hr"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <h4 class="pt-1 pl-2" v-if="user">
                Naručio:
                {{ user.name.toUpperCase() + " " + user.surname.toUpperCase() }}
              </h4>
            </v-row>

            <v-row class="pa-0 ma-0"> </v-row>
            <br />
            <v-data-table
              v-if="
                order !== undefined &&
                order.items !== undefined &&
                order.items.length > 0
              "
              :headers="orderHeaders"
              :items="order.items"
              :items-per-page="-1"
              :search="searchTable"
              class="elevation-1 pb-5 mb-5"
              hide-default-footer
              height="58vh"
              fixed-header
              dense
              :custom-filter="filterOnlyCapsText"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Proizvodi</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="searchTable"
                    label="Pretraži proizvode"
                    class="mt-4"
                  ></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td></td>
                  <td>{{ item.code }}</td>
                  <td>{{ item.name }}</td>
                  <td>
                    <v-text-field
                      class="pa-0 ma-0 mb-5 pb-4 pr-20"
                      dense
                      v-model="item.price"
                      :value="item.price / 1000"
                      style="
                        height: 12px !important;
                        width: 80px;
                        font-size: 14px;
                      "
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="pa-0 ma-0 mb-5 pb-4 pr-20"
                      dense
                      v-model="item.margin"
                      style="
                        height: 12px !important;
                        width: 80px;
                        font-size: 14px;
                      "
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="pa-0 ma-0 mb-5 pb-4 pr-20"
                      dense
                      v-model="item.pdv"
                      style="
                        height: 12px !important;
                        width: 80px;
                        font-size: 14px;
                      "
                    ></v-text-field>
                  </td>
                  <td>{{ item.quantity_in_package }}</td>
                  <td>
                    <v-text-field
                      class="pa-0 ma-0 mb-5 pb-4 pr-20"
                      dense
                      v-model="item.quantity"
                      style="
                        height: 12px !important;
                        width: 80px;
                        font-size: 14px;
                      "
                      @change="changedQuantity(item)"
                    ></v-text-field>
                  </td>
                </tr>
              </template>
            </v-data-table>

            <div class="text-right">
              <h4>
                Iznos narudžbe:
                {{ total  }}
              </h4>
              <br />
              <v-btn
                right
                bottom
                class="white--text loginButton"
                @click="finishOrder()"
                :disabled="disabled"
              >
                Naruči
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-overlay v-if="loading" absolute>
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>

import rules from '@/plugins/rules'
import { df, auth } from '@/plugins/firebase'
import state from '@/state'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings'
import { v4 as uuidv4 } from 'uuid'
import EventBus from '@/plugins/event-bus'
import { DAY, toISOLocal, clone } from '@/plugins/utils'

export default {
  components: {
  },
  mixins: [flow, applicationSettings],
  inject: [
    'showMsgBox',
    'showLoader',
    'hideLoader',
    'openPdfPrint',
    'closePdfPrint'
  ],
  data: function () {
    return {
      dialogOpen: false,
      loading: false,
      currency: 'EUR',
      order: {},
      user: undefined,
      selected: {},
      multiplier: 0,
      searchTable: undefined,
      show: false,
      locations: [
      ],
      location: undefined,
      paymentTypes: [{
        text: 'Gotovina',
        value: 'GOTOVINA'
      }, {
        text: 'Kartica',
        value: 'KARTICA'
      },
      {
        text: 'Virman',
        value: 'VIRMAN'
      }
      ],
      paymentType: undefined,
      rules: {
        req: rules.req(),
        oib: rules.oib()
      },
      orderHeaders: [
        {
          text: '',
          value: 'imageUrl'
        },
        {
          text: 'Šifra artikla',
          value: 'code'
        },
        {
          text: 'Naziv artikla',
          value: 'name'
        },
        {
          text: 'Cijena artikla',
          value: 'price'
        },
        {
          text: 'Rabat',
          value: 'margin'
        },
        {
          text: 'PDV',
          value: 'pdv'
        },
        {
          text: 'Količina u paketu',
          value: 'quantity_in_package'
        },
        {
          text: 'U narudžbi',
          value: 'quantity'
        }
      ],
      deliveryDateMenu: undefined,
      deliveryDate: new Date(new Date().getTime() - DAY)
        .toISOString()
        .substr(0, 10),
      deliveryTime: toISOLocal(new Date()).substr(11, 5),
      deliveryTimeMenu: undefined
    }
  },
  computed: {
    total () {
      var allHavePrice = true
      let tempTotal = 0
      if (this.order && this.order.items && this.order.items.length > 0) {
        this.order.items.forEach(item => {
          var price = this.formatNumber(item.price)
          if (item.price && price > 0) {
            tempTotal += (price * this.formatNumber(item.quantity, 1))
          } else {
            allHavePrice = false
          }
        })
      } else {
        return 0
      }

      if (allHavePrice === true) {
        return tempTotal / 100
      } else {
        return 0
      }
    },
    disabled () {
      if (!this.location || !this.paymentType) {
        return true
      } else {
        return false
      }
    },
    now () {
      return parseInt((`${new Date().getTime()}` / 1000).toFixed(0))
    },
    locs: {
      get: function () {
        return this.locations
      },
      set: function (nv) {
        this.locations = [
          { name: this.$t('$vuetify.all'), id: '', location_id: '' }
        ].concat(nv)
      }
    },
    formatDeliveryDate () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.deliveryDate))
    },
    formatDeliveryTime () {
      return new Date(`${this.deliveryDate}T${this.deliveryTime}`).toLocaleTimeString(
        [],
        { hour: '2-digit', minute: '2-digit', hour12: false }
      )
    },
    dateDT__datetime () {
      return parseInt(
        (new Date(`${this.deliveryDate}T${this.deliveryTime}`).getTime() / 1000).toFixed(0)
      )
    }
  },
  mounted () {
    this.user = state.getUser()
    this.getMultiplier()

    const docRef = df.doc(
      `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
    )
    const listener = docRef.onSnapshot(doc => {
      if (
        doc &&
        doc.data() &&
        doc.data().locations &&
        Object.keys(doc.data().locations).length > 0
      ) {
        this.locations = Object.keys(doc.data().locations).map(key => {
          return doc.data().locations[key]
        })
      }
    })
    this.listeners.push(listener)
  },
  methods: {
    async finishOrder () {
      this.loading = true
      const reqId = uuidv4()

      var orItems = clone(this.order.items)

      orItems.forEach((item, itKey) => {
        var quantity = orItems[itKey].quantity
        orItems[itKey].quantity = parseInt(parseFloat(quantity.replace('.', '').replace(',', '.')) * this.multiplier)

        if (item.price && item.price !== '') {
          orItems[itKey].price = this.formatNumber(orItems[itKey].price, 100)
        }

        if (item.pdv && item.pdv !== '') {
          orItems[itKey].pdv = this.formatNumber(orItems[itKey].pdv, 100)
        }

        if (item.margin && item.margin !== '') {
          orItems[itKey].margin = this.formatNumber(orItems[itKey].margin, 100)
        }
      })

      try {
        const payload = {
          action: {
            operation: 'set',
            entity: 'supplier_order',
            params: {
              company_id: state.getCurrentCompany().id,
              location_id: this.location.id,
              supplier_id: this.order.supplier_id,
              delivery_date: this.dateDT__datetime,
              payment_method: this.paymentType,
              items: orItems
            }
          }
        }

        var userID = auth.currentUser.uid
        if (state.getCashRegisterUser().id !== undefined && state.getCashRegisterUser().id !== '') {
          userID = state.getCashRegisterUser().id
        }

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${userID}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'supplier',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .catch((err) => {
            this.loading = false

            this.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })
          })

        const docRef = df.doc(`response/${reqId}`)
        const unsubscribe = docRef.onSnapshot((doc) => {
          if (this.$refs && doc.data()) {
            unsubscribe()
            this.showMsgBox({
              text: 'Narudžba poslana!',
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })
            EventBus.$emit('resetOrder', true)
            this.loading = false
            this.dialogOpen = false
          }
        })
      } catch (err) {
        this.loading = false

        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
      }
    },
    formatNumber (num, multiplier = 100) {
      if (!num || num === '' || num === 0) return 0

      var bla = num.toString().trim()
      if (bla === '') return 0

      var number = bla.replace(/,/g, '.')
      number = parseFloat(number)
      return parseInt(number * multiplier)
    },
    filterOnlyCapsText (value, search, item) {
      return value != null &&
         search.toUpperCase() != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search.toUpperCase()) !== -1
    },
    changedQuantity (item) {
      let diff = item.quantity - item.quantityOld

      if (diff === 0) {
        diff = -item.quantity
      }

      this.order.items.forEach((it, key) => {
        if (item.name === it.name) {
          this.order.items[key].quantityOld = it.quantity
        }
      })
    },
    open (order) {
      this.order = []

      var or = clone(order)
      or.items.forEach((item, key) => {
        if (item.price && item.price !== 0) {
          or.items[key].price /= 100
        }

        if (item.margin && item.margin !== 0) {
          or.items[key].margin /= 100
        }

        if (item.pdv && item.pdv !== 0) {
          or.items[key].pdv /= 100
        }

        if (item.quantity_in_package && item.quantity_in_package !== 0) {
          or.items[key].quantity_in_package /= this.multiplier
        }
      })
      this.order = or

      this.location = order.location
      this.locations = [order.location]

      this.dialogOpen = true
    },

    navigateToFlow () {
      this.$router.push({
        name: 'Home',
        params: {
        }
      })
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    close () {
      this.dialogOpen = false
      this.order = []
    }
  }
}
</script>
<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}
tr:hover {
  cursor: pointer;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: 20px !important;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
