<template>
  <v-sheet
    class="elevation-6 fill-height receipt-calculator mr-5"
    height="auto"
    shaped
  >
    <v-card>
      <v-card-text class="mx-0 px-0 pt-0">
        <v-window v-model="step">
          <v-window-item :value="1">
            <order-items-view
              @advanceStep="advanceStep"
              @currencyChanged="setCurrency"
              @totalChange="totalChange"
            ></order-items-view>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>
<script>
import OrderItemsView from '@/modules/point-of-sale/components/orders/other/receive-order/calculator/OrderItemsViewReceive'
import { df } from '@/plugins/firebase'
import state from '@/state'

export default {
  name: 'OrderCalculatorOther',
  components: { OrderItemsView },
  data: () => ({
    search: '',
    currency: undefined,
    type: {},
    total: 0,
    step: 1,
    wizardData: {},
    paymentConfig: {},
    props: {
      supplier: {
        type: Object,
        default: () => {}
      }
    }
  }),
  computed: {
    has_certificate () {
      return state.getCurrentCompany().certificate
    },
    registerType () {
      return state.getCashRegister().type
    }
  },
  watch: {
    step (val) {
      if (val === 2) {
        this.$emit('transactionStarted')
      }
      if (val === 1) {
        this.$emit('transactionEnded')
      }
    }

  },
  mounted () {
  },
  methods: {
    advanceStep (data) {
      this.wizardData = { ...this.wizardData, ...data }
      this.step++
    },
    backwardStep (deletableProperties = null) {
      if (deletableProperties) {
        deletableProperties.forEach(prop => {
          if (Object.prototype.hasOwnProperty.call(this.wizardData, prop)) {
            delete this.wizardData[prop]
          }
        })
      }
      this.step--
    },
    paymentDone () {
      this.wizardData = {}
      this.wizardData.paymentConfig = this.paymentConfig
      if (this.step === 3) {
        this.$refs.paymentMode.setDefaults()
      }
      this.step = 1

      this.$emit('transactionEndedWithReceipt')
    },
    totalChange (total) {
      this.total = total
    },
    setCurrency (currency) {
      this.currency = currency
      this.$emit('currencyChanged', this.currency)
    },
    async getCashRegisterConfiguration () {
      if (!this.registerType) return {}
      const paymentConfig = await df.doc('project_constants/config').get()
      // TODO Provjeriti ovaj uvjet
      // if (!paymentConfig?.data()?.cash_registers_web?.[this.registerType]) return {}
      if (!this.has_certificate) return { offer: paymentConfig.data().cash_registers_web.offer[this.registerType]['!CERT'], receipt: paymentConfig.data().cash_registers_web.receipt[this.registerType]['!CERT'] } || {}
      return { offer: paymentConfig.data().cash_registers_web.offer?.[this.registerType].CERT, receipt: paymentConfig.data().cash_registers_web.receipt?.[this.registerType].CERT } || {}
    }
  }
}
</script>
<style scoped>
.receipt-calculator {
  position: sticky;
  top: 84px;
  overflow-y: auto;
}
</style>
