<template>
  <div>
    <!-- <confirm ref="confirmStorno" @selection="cancelReceipt" />
    <confirm ref="confirmFiscalize" @selection="fiscalizeReceipt" /> -->
    <v-container>
      <v-row
        style="margin-top: 2px; margin-bottom: -2rem"
        class="d-flex justify-center"
      >
        <!-- <v-col cols="3" sm="3" md="2" xl="2">
          <v-select
            :label="$t('$vuetify.companyTabs.pointsOfSale')"
            v-model="location"
            :items="locs"
            return-object
            item-text="name"
            item-value="location_id"
          >
            <template v-slot:item="{ item }">
              {{ item.name }}{{ item.id !== "" ? "," : "" }} {{ item.address }}
            </template>
          </v-select>
        </v-col> -->
        <v-col cols="4" sm="4" md="3" xl="2">
          <v-select
            label="Status narudžbe"
            v-model="statusFilter"
            :items="filters"
            return-object
            item-text="name"
            item-value="value"
          >
            <template v-slot:item="{ item }">
              {{ item.name }}
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row style="margin-top: 20px" class="d-flex justify-center">
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-expansion-panels focusable>
            <v-expansion-panel
              v-for="(order, i) in orders"
              :key="i"
              hover
              tabindex="0"
            >
              <v-expansion-panel-header
                height="200"
                :class="[
                  order.status === 2 ? 'status-ok' : '',
                  order.status === 3 ? 'status-ok' : '',
                  order.status === 4 ? 'status-ok' : '',
                  order.status === 5 ? 'status-ok' : '',
                ]"
                hide-actions
              >
                <v-row>
                  <v-col
                    cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding]"
                  >
                    <div class="caption grey--text text-center">
                      Broj narudžbe
                    </div>
                    <div class="text-center">{{ order.orderId }}</div>
                  </v-col>
                  <v-col
                    cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding, 'flex-shrink-0']"
                    v-if="admin"
                  >
                    <div class="caption grey--text text-right">
                      Iznos narudžbe
                    </div>
                    <div class="text-right">
                      {{ order.totalOrderPrice | money(1, currency) }}
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding]"
                  >
                    <div class="caption grey--text text-center">
                      Predviđen datume dostave
                    </div>
                    <div class="text-center">
                      {{ order.estimatedDeliveryTime }}
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding]"
                  >
                    <div class="d-flex justify-space-between">
                      <div>
                        <div class="caption grey--text text-left">
                          {{ $t("$vuetify.receipts.status") }}
                        </div>
                        <div class="text-left">
                          {{ showOrderStatus(order.status) }}
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    sm="4"
                    md="2"
                    lg="2"
                    xl="2"
                    :class="[densityPadding]"
                  >
                    <div class="text-center">
                      <!-- <v-btn
                        v-if="order.allowDelivery"
                        @click.prevent.stop="openOrder(order)"
                        >Kreiraj primku</v-btn> -->
                      <v-btn @click.prevent.stop="openOrder(order)"
                        >Kreiraj primku</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" sm="5">
                    <div
                      class="d-flex justify-space-between text-center text-subtitle-2"
                    >
                      Operater: {{ order.createSystemUser.name }}
                    </div>
                    <div
                      class="d-flex justify-space-between text-center text-subtitle-2"
                    >
                      Način plaćanja: {{ order.receiptType }}
                    </div>
                  </v-col>
                </v-row>
                <v-list>
                  <v-row>
                    <v-col>
                      <div
                        class="text-center text-subtitle-2 one-liner-text font-weight-bold"
                      >
                        R. br.
                      </div>
                    </v-col>
                    <v-col>
                      <div
                        class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold"
                      >
                        Naziv
                      </div>
                    </v-col>
                    <v-col>
                      <div
                        class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold"
                      >
                        Količina
                      </div>
                    </v-col>
                    <v-col>
                      <div
                        class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold"
                      >
                        Jedinična cijena
                      </div>
                    </v-col>
                    <v-col>
                      <div
                        class="text-center text-center text-subtitle-2 one-liner-text font-weight-bold"
                      >
                        Cijena
                      </div>
                    </v-col>
                  </v-row>
                  <v-list-item
                    v-for="(item, index) in order.products"
                    :key="index"
                    class="pa-0"
                  >
                    <v-row class="pa-0">
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ index + 1 }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ item.articleName }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ item.quantity }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ item.unitPriceWithDiscount | money(1, currency) }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ item.totalPriceForQuantity | money(1, currency) }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <infinite-loading
        ref="InfiniteLoading"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </v-container>
    <receive-order
      ref="receiveOrder"
      @deliveryNoteDone="deliveryNoteDone"
    ></receive-order>
  </div>
</template>
<script>
import rules from '@/plugins/rules'
import { functions, df, auth } from '@/plugins/firebase'
import state from '@/state'
import InfiniteLoading from 'vue-infinite-loading'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings'
import { clone } from '@/plugins/utils'
import ReceiveOrder from '@/modules/point-of-sale/components/orders/roto/receive-order/ReceiveOrderRoto.vue'

export default {
  components: {
    InfiniteLoading,
    ReceiveOrder
  },
  mixins: [flow, applicationSettings],
  inject: [
    'showMsgBox',
    'showLoader',
    'hideLoader',
    'openPdfPrint',
    'closePdfPrint'
  ],
  data: function () {
    return {
      admin: false,
      loading: false,
      orders: [],
      location: {},
      locations: [],
      associates: [],
      statusFilter: {},
      lastVisible: {},
      listeners: [],
      selected: {},
      multiplier: 0,
      show: false,
      rules: {
        req: rules.req(),
        oib: rules.oib()
      },
      currency: 'EUR'
    }
  },
  props: {
    supplier: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    now () {
      return parseInt((`${new Date().getTime()}` / 1000).toFixed(0))
    },
    locs: {
      get: function () {
        return this.locations
      },
      set: function (nv) {
        this.locations = [
          { name: this.$t('$vuetify.all'), id: '', location_id: '' }
        ].concat(nv)
      }
    },
    filters () {
      return [
        { name: 'Dostavljeno', value: 5 },
        { name: 'U dostavi', value: 4 },
        { name: 'U pripremi', value: 3 },
        { name: 'Zaprimljeno', value: 2 }
      ]
    }
  },
  watch: {
    location: {
      deep: true,
      handler (nv, ov) {
        if (nv !== ov) {
          // paginate more columns
          this.lastVisible = {}
          this.orders = []
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    },
    statusFilter: {
      deep: true,
      handler (nv, ov) {
        if (nv !== ov) {
          // paginate more columns
          this.lastVisible = {}
          this.orders = []
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    }
  },
  async mounted () {
    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }
    this.admin = state.getUser()?.roles?.SUPERADMIN || state.getUser()?.roles?.ADMIN
    this.admin = !this.admin

    this.getAssociates()
    this.getMultiplier()

    this.locations = [
      { name: this.$t('$vuetify.all'), id: '', location_id: '' }
    ]
    this.location = this.locations[0]

    const docRef = df.doc(
      `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
    )
    const listener = docRef.onSnapshot(doc => {
      if (
        doc &&
        doc.data() &&
        doc.data().locations &&
        Object.keys(doc.data().locations).length > 0
      ) {
        this.locs = Object.keys(doc.data().locations).map(key => {
          return doc.data().locations[key]
        })
      }
    })
    this.listeners.push(listener)
  },
  methods: {
    deliveryNoteDone () {
      this.$refs.InfiniteLoading.stateChanger.reset()
    },
    async checkDeliveryNote (orders) {
      const orderIds = []
      if (orders !== undefined && orders.length > 0) {
        orders.forEach(order => {
          orderIds.push(order.orderId.toString())
        })
      }

      if (orderIds !== undefined && orderIds.length > 0) {
        const query = await df.collection('delivery_notes')
          .where('id', 'in', orderIds)
          .get()

        const deliveryNotes = query.docs.map(doc => doc.data())

        if (deliveryNotes !== undefined && deliveryNotes.length > 0) {
          deliveryNotes.forEach(note => {
            this.orders.forEach(order => {
              if (order.orderId === parseInt(note.id)) {
                order.allowDelivery = false
              }
            })
          })
        }
        this.$forceUpdate()
      }
    },
    openOrder (order) {
      this.$refs.receiveOrder.open(order)
    },
    showOrderStatus (status) {
      switch (status) {
        case 2:
          return 'Zaprimljeno'
        case 3:
          return 'U pripremi'
        case 4:
          return 'U dostavi'
        case 5:
          return 'Dostavljeno'
        default:
          return 'Nepoznat status'
      }
    },
    applyFilter () {
      this.lastVisible = {}
      this.orders = []
      this.$refs.InfiniteLoading.stateChanger.reset()
    },

    navigateToFlow () {
      this.$router.push({
        name: 'Home',
        params: {
        }
      })
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    infiniteHandler ($state) {
      // const where = that.location ? that.location.location_id : ''
      const status = this.statusFilter ? this.statusFilter.value : ''

      if (this.supplier === undefined || this.supplier === '') {
        $state.complete()
        return
      }

      const params = {
        company_id: state.getCurrentCompany().id,
        supplier: this.supplier.id
      }

      if (status !== undefined && status !== 0) {
        params.status = status
      }

      var ordersApi = functions.httpsCallable('rotoorders')
      ordersApi({
        action: {
          operation: 'get',
          entity: 'order',
          params: params
        },
        attributes: {
        }
      })
        .then((result) => {
          if (result.data !== undefined && result.data.data !== undefined && result.data.data.data !== undefined) {
            this.orders = result.data.data.data
            this.orders.forEach(order => {
              order.allowDelivery = true
            })
            this.checkDeliveryNote(this.orders)
            $state.complete()
          }

          // if (this.orderCart !== undefined) {
          //   if (this.orderCart.products !== undefined && this.orderCart.products.length > 0) {
          //     this.items = this.orderCart.products
          //   }

          //   this.total = this.orderCart.totalOrderPrice
          // }
        })
    },
    getOrders () {
      this.lastVisible = ''
      this.orders = []

      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },
    getAssociates () {
      df.doc(`company_associates/${state.getCurrentCompany().id}`)
        .get()
        .then((resp) => {
          if (resp && resp.data()) {
            this.defaultAssociates = Object.keys(resp.data().associates)
              .map((key) => {
                return resp.data().associates[key]
              })
              .filter((associate) => associate.status === 'OK' && associate.associate_type.BUYER)
            this.associates = clone(this.defaultAssociates)
          }
        })
    }
  }
}
</script>
<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}
tr:hover {
  cursor: pointer;
}
</style>

<style>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
